import React, { Component, Fragment } from 'react';
import data from '../../assets/json/resources.json';
import Triangle from '../shared/DropdownTriangle/Triangle';


class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showAlert: false,
      data: data,

    }
    this.content = null;
  }

  toggleAccordion = (e) => {  //Collapses all dropdowns and opens target dropdown


    this.setState(
      {
        [`section-1-show`]: false,
        [`section-2-show`]: false,
        [`section-3-show`]: false,
        [`section-4-show`]: false,
        [`section-5-show`]: false,
        [`section-6-show`]: false,
        [`section-7-show`]: false,
        [`section-${e.target.dataset.section}-show`]: this.state[`section-${e.target.dataset.section}-show`] ? !this.state[`section-${e.target.dataset.section}-show`] : true,

      }, () => {
      }
    )

  }
  buildPage = () => {

    const { data } = this.state;
    return (
      <Fragment>
        {/* <div className="inner-content">
          <p>{data.life.summary}</p>
          <p>{data.life.summary_wrap}</p>
        </div> */}
        {data.life.dropdowns.map((section, i) => {
          return (
            <div key={i} className={`dropdown-section ${this.state[`section-${i + 1}-show`] === true ? 'panel-show' : null}`}>
              <h3 key={i} data-section={i + 1} onClick={this.toggleAccordion}>{section.header}
                <span className="dropdown-header">
                  <div className="expand-icon"><Triangle data={i + 1} id="icon" /></div>
                </span>
              </h3>
              <div className={this.state[`section-${i + 1}-show`] === true ? "inner-section" : 'hide'}>

                <ul>
                  {section.areas.map((point, i) => <li key={i}>{point}</li>)}
                </ul>
                {
                  section.Attractions ?
                    <>
                      <h4>
                        Attractions
                  </h4>

                      <ul>
                        {section.Attractions.map((point, i) => <li key={i}>{point}</li>)}
                      </ul></> :
                    <></>
                }
                {section.Families ?
                  <>
                    <h4>
                      For Families
                  </h4>
                    <ul>
                      {section.Families.map((point, i) => <li key={i}> {point}</li>)}
                    </ul>
                  </> :
                  <></>
                }

                {
                  section.Shopping ?
                    <>
                      <h4>
                        Shopping
                  </h4>
                      <ul>
                        {section.Shopping.map((point, i) => <li key={i}>{point}</li>)}
                      </ul>
                    </> :
                    <></>
                }
              </div>
            </div>
          )
        })}
      </Fragment>
    )
  }

  render() {
    return (
      <>

        <div className="resource-container">
          <h1 className='boldMe'>Resources:</h1>
          <p>
            Below are a few resources that may be helpful to you and/or your family member as you navigate life with an Ignition Interlock. They won’t apply to everyone, but share if you or someone you know could use the information.
        </p>
          <br />
          <br />
          <p className='boldMe'>
            Activities in New Mexico that Don’t Involve Alcohol
        </p>
          <p className='resourceList'>
            There are many things to do in New Mexico. Click on the regions below to see examples of activities to do with your family member that do not involve alcohol.
        </p>
        </div>


        {this.buildPage()}
        <div className="resource-container">



          <br />
          <br />
          <p className='boldMe'>
            Here are Some Other Resources You Might Find Helpful
      </p>
          <p>

            <ul className='resourceList'>
              <li>
                <a href='https://www.samhsa.gov/find-help/national-helpline' rel="noopener noreferrer" target="_blank" >SAMHSA’s National Helpline </a> - 1-800-662-HELP (4357)
          </li>

              <li>
                <a href=' https://findtreatment.gov/' rel="noopener noreferrer" target="_blank">Treatment Programs</a>
              </li>
              <li>
                <a href='http://www.mvd.newmexico.gov/what-to-do-after-a-dwi.aspx' rel="noopener noreferrer" target="_blank">What to do after a DWI from New Mexico MVD</a>
              </li>
              <li>
                <a href='http://www.endwi.com/' rel="noopener noreferrer" target="_blank">End DWI Campaign</a>
                <li>
                  <a href='https://dot.state.nm.us/content/nmdot/en/Traffic_Safety.html' rel="noopener noreferrer" target="_blank">New Mexico Department of Transportation Traffic Safety</a>
                </li>
              </li>
            </ul>
          </p>
          <br />
          <p className='boldMe'>
            Find a Local Support Group
        </p>
          <br />

          <p>

            <ul className='resourceList'>

              <li>
                <a target="_blank" rel="noopener noreferrer" href='https://nm-aa.org/meetings/?tsml-day=any&tsml-view=map'>List of AA meetings in New Mexico</a>
              </li>

              <li>

                <a target="_blank" rel="noopener noreferrer" href='https://alcoholicsanonymous.com/aa-meetings/new-mexico/'> Find AA Meetings in New Mexico</a>

              </li>


              <li>
                <a href='https://www.aa.org/assets/en_US/p-87_AAforAlcoholicswithMentalHealthIssues.pdf' rel="noopener noreferrer" target="_blank">AA for Addicts with Mental Health Issues </a>
              </li>
              <li>
                <a href='https://www.smartrecovery.org/local-meetings/' rel="noopener noreferrer" target="_blank">SMART Recovery</a>
              </li>

            </ul>
          </p>


        </div>

        <div className='bottomSpace'>

        </div>
      </>
    )
  }
}

export default Resources;