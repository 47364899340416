import React, { Component, Fragment } from 'react'
import ProgHolder from '../shared/ModProgBar';
import Loader from '../shared/Loader';
import { Consumer, Context } from '../../context';
import Button from '../shared/Button';
import Alert from '../shared/Alert';
import { Transition } from 'react-transition-group';
import { TweenMax } from 'gsap';
import LoginService from '../../utils/LoginService';



class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showIntroAlert: false,
      firstTimeVisitor: true,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      LoginService.isLoggedIn() ?

        this.setState({ isLoading: !this.state.isLoading }, () => {
          if (this.context.state.firstTimeVisitor === true) {
            setTimeout(() => {
              this.setState({ showIntroAlert: true })
            }, 1000)
          }
        }) :
        window.location = '/';
    }, 2500)

  }


  buildNav = () => {
    const moduleNavs = this.context.state.modules.map((module, i) => {
      return (
        <div key={i} className="module-links">
          <h1>{module.id}. {module.name}</h1>
          <div className="module-start-buttons">
            <Button
              text={this.context.state[`module${module.id}status`] === 0 ? "Start" : this.context.state[`module${module.id}status`] === 1 ? "Continue" : "Replay"}
              icon={this.context.state[`module${module.id}status`] === 0 ? "next" : this.context.state[`module${module.id}status`] === 1 ? "next" : "replay"}
              disabled={this.context.state.unlockedMods.includes(module.id) ? false : true}
              onClick={(e) => {
                this.context.preventHomeInstruct();
                if (this.context.state[`module${module.id}status`] !== 2) {
                  this.context.updateCurrentMod(module.id)
                  this.context.updateProgress(module.id, 1, null)
                  // this.context.initContext();
                  if (this.context.state[`module${module.id}status`] === 1) {
                    this.props.history.push(module.pages[this.context.state[`module${module.id}pages-completed`]].path)
                  } else {
                    this.props.history.push(module.pages[0].path)
                  }
                } else {
                  this.context.updateCurrentMod(module.id)
                  this.props.history.push(module.pages[0].path)
                }
              }}
            />
            {module.id !== 1 ?

              <Button
                text="Bookmarks"
                icon="info"
                disabled={this.context.state[`module${module.id}status`] === 2 || this.context.state.unlockedMods.includes(module.id) ? false : true}
                onClick={() => this.props.history.push(`/mod-${module.id}-results`)}
              /> :
              <div className='ghost'>

                <Button
                  text="Bookmarks"
                  icon="info"
                  disabled={this.context.state[`module${module.id}status`] === 2 || this.context.state.unlockedMods.includes(module.id) ? false : true}
                  onClick={() => this.props.history.push(`/mod-${module.id}-results`)}
                /> </div>
            }
          </div>
        </div>
      )
    })
    return moduleNavs;
  }

  renderLoader = () => {
    if (this.state.isLoading) {
      return (
        <Loader in={this.state.isLoading} />
      )
    } else {
      return (
        <Transition
          timeout={5000}
          mountOnEnter
          unmountOnExit
          appear
          in={!this.state.isLoading}
          addEndListener={(node, done) => {
            TweenMax.from(node, 1, { autoAlpha: !this.state.isLoading ? 0 : 1, onComplete: done });
          }}
        >
          <Consumer>
            {(context) => (
              <div>
                <ProgHolder />
                <Alert
                  expand
                  showAlert={this.state.showIntroAlert}
                  text="Welcome to the B-SMART app!"
                  onClose={() => {
                    this.setState({ showIntroAlert: false, firstTimeVisitor: false })
                  }}
                >
                  <p>This app is designed to help you and your family member cope with the Driving While Intoxicated (DWI) conviction and the Ignition Interlock Device (Interlock).  The B-SMART app provides important information about:</p>
                  <ol className="intro-popup-list">
                    <li>The Interlock device in your car.</li>
                    <li>How you and your family can adjust to it.</li>
                    <li>Advice about how to successfully get through the DWI conviction experience and improve your lives.</li>
                    <li>How to not repeat this difficult experience again.</li>
                  </ol>
                  <p>The B-SMART app’s four modules provide interactive experiences and tools that you and your family member should use frequently.  So, please do so – it will make your life better both now and in the future.</p>
                </Alert>
                <div className="module-nav">
                  {this.buildNav()}
                </div>
              </div>
            )}
          </Consumer>
        </Transition>
      )
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderLoader()}
      </Fragment>
    )
  }
}

Home.contextType = Context;
export default Home;
