import React, { Component } from 'react'
import { Consumer, Context } from '../../../context';
import Button from '../../shared/Button';


class Mod3Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedActs: []
    }
  }

  componentDidMount() {
    this.hydrateState('savedActs');
  }

  hydrateState = async (req) => {
    if (this.context.state.userToken) {
      let response = await this.context.getItem(req);
      if (response) {
        let value = JSON.parse(response.value);
        this.setState({ savedActs: value });
      }
    }
    else {
      this.middleWare()
    }
  }
  middleWare = () => {
    setTimeout(() => {
      this.hydrateState('savedActs')
    }, 500)
  }

  createActList = () => {
    if (this.state.savedActs) {
      const acts = this.state.savedActs.map((act, i) => {
        return <h3 key={i}>{act}</h3>
      })
      return acts;
    }
  }

  render() {
    return (
      <div>
        <div className="reminder-prompt">
          <h1>Your Bookmarks:<br />Doing Things Together</h1>
          <div className="btn-holder">
            <Button
              text="Add Reminders"
              icon="add"
              onClick={() => this.props.history.push('/mod-3-remind')}
            />
          </div>
        </div>
        <h2 className="red-sub-headers">Your Saved Activities:</h2>
        <div className="saved-acts-list">
          {this.state.savedActs !== [] ? this.createActList() : null}
        </div>
        <div className="button-container">
          <Button
            text="Home"
            icon="next"
            onClick={() => this.props.history.push('/home')}
          />
        </div>
      </div>
    )
  }
}
Mod3Results.contextType = Context;
export default Mod3Results;