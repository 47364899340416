import React, { Component } from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../../assets/json/bsmart_loader.json';
import styles from './Loader.module.scss';

class LottieControl extends Component {

  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div className={styles.loaderContainer}>
        <div className={styles.innerCont}>
          <Lottie 
            options={defaultOptions}
            isStopped={this.state.isStopped}
            isPaused={this.state.isPaused}
          />
        </div>
      </div>
    )
  }
}

export default LottieControl