import React, { Component } from 'react'
import { Consumer, Context } from '../../../context';
import data from '../../../assets/json/skills_video_act.json';
import PageProgBar from '../../shared/PageProgBar';
import VideoPlayer from '../../shared/VideoPlayer';
import Alert from '../../shared/Alert';
import QuestionAlert from '../../shared/QuestionAlert';
import Button from '../../shared/Button';
class Mod4Page2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      actStarted: false,
      activityEnded: false,
      showAlert: false,
      showDo: false,
      showDont: false,
      showAnother: true,
      showQuestionAlert: false,
      alertText: data.skills[0].do,
      secondVidPlaying: false,
      alertStyle: 'green',
      currentSkill: 1,
      currentCCPath: '',
      currentVidPath: '',
      hideFunc: this.onAlertClose,
      showFinal: false,
      isSkillOne: true,
      showInstructions: false,
      showFeedbackOne: false,
      showFeedbackTwo: false
    }
  }

  componentDidMount() {
    const _Localize = window.Localize;
    let currentLanguage = _Localize.getLanguage();
    let activeSkill = this.state.data.skills[this.state.currentSkill - 1];
    this.setState({
      lang: currentLanguage,
      currentVidPath: currentLanguage === 'en' ? activeSkill.vid_path_dont : activeSkill.vid_path_dont_es,
      currentCCPath: currentLanguage === 'en' ? activeSkill.cc_path_dont : activeSkill.cc_path_dont_es
    })
    setTimeout(() => {
      this.setState({
        // showDont: true,
        showInstructions: true
      })
    }, 1000)
  }

  onVideoEnd = () => {
    this.showQuestions();
  }

  showQuestions = () => {
    if (this.state.secondVidPlaying) {
      this.setState({
        showQuestion2Alert: true
      })
    } else {
      this.setState({
        showQuestionAlert: true
      })
    }
  }

  showSecondFeedback = () => {
    this.setState({
      showQuestion2Alert: false
    }, () => {
      setTimeout(() => {
        this.setState({ showFeedbackTwo: this.state.currentSkill === 1 ? false : true })
      }, 700)
      if (this.state.currentSkill === 1) {
        this.closeAndAdvanceShowDont()
      }
    })
  }

  simpleClose = () => {
    this.setState({
      showAlert: false
    })
  }

  closeDo = () => {
    this.setState({
      showDo: false,
      secondVidPlaying: true
    })
  }

  closeDont = () => {
    this.setState({
      showDont: false
    })
  }

  getDoText = () => {
    let text = this.state.data.skills[this.state.currentSkill - 1].do;
    return text;
  }

  getDontText = () => {
    let text = this.state.data.skills[this.state.currentSkill - 1].dont;
    return text;
  }

  getPromptOne = () => {
    let text = this.state.data.skills[this.state.currentSkill - 1].prompt_one;
    return text;
  }

  getPromptTwo = () => {
    let text = this.state.data.skills[this.state.currentSkill - 1].prompt_two;
    return text;
  }

  getPromptThree = () => {
    let text = this.state.data.skills[this.state.currentSkill - 1].prompt_three;
    return text;
  }

  getPromptFour = () => {
    let text = this.state.data.skills[this.state.currentSkill - 1].prompt_four;
    return text;
  }

  showDo = () => {
    setTimeout(() => {
      this.setState({ showDo: true })
    }, 700)
  }

  showDont = () => {
    setTimeout(() => {
      this.setState({ showDont: true })
    }, 700)
  }

  showFinal = () => {
    setTimeout(() => {
      this.setState({
        showFinal: true
      })
    }, 750)
  }

  closeAndAdvance = () => {
    let activeSkill = this.state.data.skills[this.state.currentSkill - 1];
    this.setState({
      showQuestionAlert: false,
      currentVidPath: this.state.lang === 'en' ? activeSkill.vid_path_do : activeSkill.vid_path_do_es,
      currentCCPath: this.state.lang === 'en' ? activeSkill.cc_path_do : activeSkill.cc_path_do_es
    }, () => {
      setTimeout(() => {
        this.setState({ showFeedbackOne: this.state.currentSkill === 1 ? false : true, })
      }, 700)
      if (this.state.currentSkill === 1) {
        setTimeout(() => {
          this.showDo();
        }, 700)
      }
    })
  }

  closeAndAdvanceShowDo = () => {
    this.setState({ showFeedbackOne: false }, () => {
      this.showDo()
    })
  }

  closeAndAdvanceShowDont = () => {
    this.setState({ showFeedbackTwo: false }, () => {
      setTimeout(() => {
        if (this.state.currentSkill === this.state.data.skills.length) {
          this.setState({ activityEnded: true })
          return;
        } else {
          this.setState({
            currentSkill: this.state.currentSkill + 1,
            secondVidPlaying: false,
            currentVidPath: this.state.lang === 'en' ? this.state.data.skills[this.state.currentSkill].vid_path_dont : this.state.data.skills[this.state.currentSkill].vid_path_dont_es,
            currentCCPath: this.state.lang === 'en' ? this.state.data.skills[this.state.currentSkill].cc_path_dont : this.state.data.skills[this.state.currentSkill].cc_path_dont_es,
            isSkillOne: false
          }, () => {

            this.showDont();
          })
        }
      }, 700)
    })
  }

  videoGenerator = (path, ccPath, ccStatus) => {
    return (
      <VideoPlayer
        key={path}
        source={path}
        onEnded={this.onVideoEnd}
        subtitle={ccPath}
        subtitleStatus={ccStatus}
      />
    )
  }

  onClickReplay = () => {
    this.setState({
      showQuestionAlert: false,
      showQuestion2Alert: false
    })
  }

  render() {
    const {
      showDo,
      showDont,
      showFinal,
      currentVidPath,
      currentCCPath,
      currentSkill,
      showQuestionAlert,
      showQuestion2Alert,
      data } = this.state;
    return (
      <Consumer>
        {(context) => (
          <div className="vid-act-container">
            <PageProgBar pageTitle="Dos and Don'ts of Communication" />
            {this.state.activityEnded && this.showFinal()}
            <div className="skill-txt">
              <h1>Skill {currentSkill}: {data.skills[currentSkill - 1].title}</h1>
            </div>
            <Alert
              showAlert={this.state.showInstructions}
              subText={data.instruct2}
              buttonText="OK"
              onClose={() => {
                this.setState({ showInstructions: false }, () => {
                  setTimeout(() => {
                    this.setState({ showDont: true })
                  }, 750)
                })
              }}
            />
            <QuestionAlert
              showAlert={showQuestionAlert}
              prompt_one={this.getPromptThree()}
              prompt_two={this.getPromptFour()}
              onClose={this.closeAndAdvance}
              isSkillOne={this.state.isSkillOne}
              onClickReplay={this.onClickReplay}
            />
            <QuestionAlert
              showAlert={showQuestion2Alert}
              prompt_three={this.getPromptOne()}
              prompt_four={this.getPromptTwo()}
              onClose={this.showSecondFeedback}
              secondVidPlaying={this.state.secondVidPlaying}
              isSkillOne={this.state.isSkillOne}
              onClickReplay={this.onClickReplay}
            />
            <Alert
              showAlert={this.state.showFeedbackOne}
              subText={this.state.data.skills[this.state.currentSkill - 1].feedback_one}
              buttonText="OK"
              onClose={this.closeAndAdvanceShowDo}
            />
            <Alert
              showAlert={this.state.showFeedbackTwo}
              subText={this.state.data.skills[this.state.currentSkill - 1].feedback_two}
              buttonText="OK"
              onClose={this.closeAndAdvanceShowDont}
            />
            <Alert
              showAlert={showDo}
              subText={this.getDoText()}
              alertStyle="green"
              onClose={this.closeDo}
              allowDanger={true}
              subBold={false}
            />
            <Alert
              showAlert={showDont}
              subText={this.getDontText()}
              alertStyle="red"
              onClose={this.closeDont}
              allowDanger={true}
            />
            <Alert
              showAlert={showFinal}
              subText="Now let's take a look at a couple of conversations."
              subBold={true}
              onClose={() => {
                this.props.history.push(context.goNextPage(context.state.currentModule));
                if (context.state[`module${context.state.currentModule}status`] !== 2) {
                  if (!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress();
                }
              }}
              allowDanger={true}
            />
            <div className="video-player">
              {this.videoGenerator(currentVidPath, currentCCPath, context.state.subtitles)}
            </div>
            <Button
              text='Subtitles'
              icon='info'
              onClick={() => {
                context.subtitlesToggle()
              }}
            />
          </div>
        )}
      </Consumer>
    )
  }
}
Mod4Page2.contextType = Context;
export default Mod4Page2;