import React, { Component } from 'react'
import Button from '../../shared/Button';
import PageProgBar from '../../shared/PageProgBar';
import { Consumer } from '../../../context';
import VideoPlayer from '../../shared/VideoPlayer';
import { VIDEO_DOMAIN } from '../../../config';


class Mod2Page1 extends Component {
  constructor(props) {
    super(props);
    this.lang = null;
  }

  componentDidMount() {
    const _Localize = window.Localize;
    this.lang = _Localize.getLanguage();
  }

  render() {
    return (
      <Consumer>
        {(context) => (
          <div>
            <PageProgBar pageTitle="Video" />
            <div className="inner-page-content">
              <div className="col-page">
                <div className="content-left">
                  <p className="vid-summary">A concerned family member has choices about how to react to potential drinking situations. In this scenario, the drinker, Joe, expresses a desire to drink, and his wife thinks about how to respond and be supportive. Joe and his wife talk about his feelings and come up with a way to help him avoid drinking.</p>
                  <ul>
                    <li>Watch the video.</li>
                    <li>Think about your situation.</li>
                    <li>Would you have reacted in the same way?</li>
                  </ul>
                </div>
                <div className="content-right">
                  <div className="video-player-container">
                    <VideoPlayer
                      source={this.lang === 'en' ? `${VIDEO_DOMAIN}support_en.mp4` : `${VIDEO_DOMAIN}support_es.mp4`}
                      subtitle={this.lang === 'en' ? 'support_en.txt' : 'support_es.txt'}
                      subtitleStatus={context.state.subtitles}
                    />
                    <Button
                      text='Subtitles'
                      icon='info'
                      onClick={() => {
                        context.subtitlesToggle()
                      }}
                    />
                  </div>


                </div>
              </div>
              <div className="button-box">
                <Button
                  text="Next"
                  icon="next"
                  onClick={() => {
                    this.props.history.push(context.goNextPage(context.state.currentModule));
                    if (context.state[`module${context.state.currentModule}status`] !== 2) {
                      if (!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}
export default Mod2Page1