import React, { Component } from 'react'

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }


  render() {
    return (
      <div className="profile-container">
        <br />
        <div className='sideBar'>
          <h1 className='header'>Placeholder Username</h1>
          <div className='space'>

          </div>
          <h3>
            Contact Support
        </h3>
          <p>
            support@bsmartapp.org
        </p>
          <p>
           ‪(505) 216-6187
        </p>
          <button className='passwordChangeButton'>
            Change Password
        </button>
        </div>
        <br />
        < div className='paragraphContainer'>
          <p className='profileParagraph'>The B-SMART app is for both you and your family member. You can each log in using the email address you provided at enrollment. We will also send two follow-up surveys (with payment for completion) to the provided email.
          If you lose access to this email address at any time, please notify us at: support@bsmartapp.org or  ‪(505) 216-6187‬.
        <br />
            <br />
            <br />
            <br />
        The B-SMART project members respect your right to privacy. The app tracks browser and platform statistics and overall usage patterns. When we do ask for personal information, it will be for research purposes and for establishing website use.
        Information regarding research knowledge gained from this study will be reported in group form only, and personal identifiers, such as name or contact information, will never be disclosed publicly.
        </p>

        </div>
      </div>
    )
  }
}

export default Profile;