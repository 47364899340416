import React, { Component, Fragment } from 'react';
import styles from './QuestionAlert.module.scss';
import { Transition } from 'react-transition-group';
import { TweenMax, Back } from 'gsap';
import Button from '../Button';

class QuestionAlert extends Component {
  constructor(props){
    super(props);
    this.state = {
      alert: false,
      buttonClicked: false,
      dim: false
    }
    this.outer = null;
  }

  componentWillReceiveProps(nextProps){
    this.setState({ alert: nextProps.showAlert })

  }

  toggle1Btn = () => {
    this.setState({
      button2Clicked: false,
      button1Clicked: !this.state.button1Clicked
    })
  }
  
  toggle2Btn = () => {
    this.setState({
      button2Clicked: !this.state.button2Clicked,
      button1Clicked: false
    })
  }

  toggle3Btn = () => {
    this.setState({
      button4Clicked: false,
      button3Clicked: !this.state.button3Clicked
    })
  }
  
  toggle4Btn = () => {
    this.setState({
      button3Clicked: false,
      button4Clicked: !this.state.button4Clicked
    })
  }

  resetForm = () => {
    this.setState({
      button1Clicked: false,
      button2Clicked: false,
      button3Clicked: false,
      button4Clicked: false
    })
  }
  

  render(){
    const { alert } = this.state;
    return(
      <Transition
        timeout={1000}
        mountOnEnter
        unmountOnExit
        in={alert}
        addEndListener={(node, done) => {
          TweenMax.to(this.outer, .3, { 
            css: { 
              backgroundColor: alert 
              ? 'rgba(0, 0, 0, .2)' 
              : 'rgba(0, 0, 0, 0)' 
            }, 
            delay: alert 
            ? .2 
            : .1 
          })
          TweenMax.to(node, 0.5, { 
            scaleX: alert ? 1 : .8,
            scaleY: alert ? 1 : .8,
            autoAlpha: alert ? 1 : 0, 
            ease: alert ? Back.easeOut : Back.easeIn,
            onComplete: done 
          });
        }}
      >
        <div ref={outer => this.outer = outer} className={this.state.dim ? styles.alertWrapper : `${styles.alertWrapper}` }>
          <div className={`${styles.container} ${styles.transitionCard} ${styles.simple} ${styles[`${this.props.alertStyle}`]}`}>
            <p dangerouslySetInnerHTML={{ __html: !this.props.secondVidPlaying ? this.props.prompt_one : this.props.prompt_three }}></p>
            {!this.props.isSkillOne 
            ? 
            <Fragment>
              <div className={styles.buttonBox}>
                <Button 
                  onClick={this.toggle1Btn}
                  text="Yes"
                  icon="check"
                  altColor={this.state.button1Clicked ? true : null}
                />
                <Button 
                  onClick={this.toggle2Btn}
                  text="No"
                  icon="check"
                  altColor={this.state.button2Clicked ? true : null}
                />
              </div>
              <p>{!this.props.secondVidPlaying ? this.props.prompt_two : this.props.prompt_four}</p>
              <div className={styles.buttonBox}>
                <Button 
                  onClick={this.toggle3Btn}
                  text="Yes"
                  icon="check"  
                  altColor={this.state.button3Clicked ? true : null}
                />
                <Button 
                  onClick={this.toggle4Btn}
                  text="No"
                  icon="check" 
                  altColor={this.state.button4Clicked ? true : null} 
                />
              </div>
            </Fragment>
            : ''
            }
            <div className={styles.buttonBox}>
            <Button 
                onClick={this.props.onClickReplay}
                text="Watch Again"
                icon="replay"  
              />
              <Button 
                onClick={() => {
                  this.props.onClose();
                  setTimeout(() => {
                    this.resetForm();
                  }, 500)
                }}
                text="Continue"
                icon="check"  
              />
            </div>
          </div>
        </div>
      </Transition>
    )
  }
}
export default QuestionAlert;