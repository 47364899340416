import React, { Component } from 'react'
import { Consumer, Context } from '../../../context';
import PageProgBar from '../../shared/PageProgBar';
import Alert from '../../shared/Alert';
import SwipeCard from '../../shared/SwipeCard';
import swipeData from '../../../assets/json/m2_swiper.json';
import correctSound from '../../../assets/audio/correct.mp3';
import incorrectSound from '../../../assets/audio/incorrect.mp3';
import { ReactComponent as NextIcon } from '../../../assets/svgs/next.svg';
import { ReactComponent as BackIcon } from '../../../assets/svgs/back.svg';
import { TweenLite, Power3 } from 'gsap';

let data, statementArr = [], c = -1, l;  // global re-assignable variables *** //

class Mod3Page2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      showAnother: true,
      data: swipeData,
      cIndex: -1,
      sLength: null,
      swipeAmount: 150,
      textInAgain: false,
      iconText: '',
      disableContinue: true,
      decideAnswer: swipeData.instruct1,
      decideFeedback: swipeData.instruct1body,
      showNext: false,
      activityComplete: false,
      firstAlert: true,
      showWrapUp: false
    }
    this.iconText = null;
    this.iconText1 = null;
    this.feedback = null;
  }

  componentDidMount() {
    c = -1;
    data = this.state.data;
    l = data.statements.length;
    data.statements.map((statement, i) => {
      return statementArr.push(statement);
    })
    setTimeout(() => {
      this.setState({ showAlert: true })
    }, 1500);
  }

  start = () => {
    if (this.state.activityComplete) {
      return;
    } else {
      this.next();
    }
  }

  next = () => {
    c++;
    if (c < l) {
      let currentStatement = statementArr[c];
      this.setState({ currentStatement: currentStatement, activityStarted: true })
    } else {
      document.removeEventListener('mousemove', this.onMove);
      this.setState({ activityStarted: false, activityComplete: true, disableContinue: !this.state.disableContinue }, () => {
        this.showNextButton()
      })
    }
  }

  textIn = (text, img) => {
    if (c < l) {
      return (
        <SwipeCard
          text={text}
          newImg={img}
          prepAlert={this.prepAlert}
          showAlert={this.state.showAlert}
          iconRef={this.iconText}
          menuStatus={this.context.state.menuShow}
          activityStarted={this.state.activityStarted}
          loadShooter={this.loadShooter}
        />
      )
    }
  }

  alertClose = (showAnother, hideFunc) => {
    this.setState({ showAlert: !this.state.showAlert, playAudio: false }, () => {
      if (showAnother === true) {
        setTimeout(() => {
          this.setState({
            showAlert: !this.state.showAlert,
            showAnother: !this.state.showAnother,
            decideAnswer: data.instruct2,
            decideFeedback: data.instruct2body,
            firstAlert: false
          })
        }, 1000);
      } else {
        hideFunc();
      }
    })
  }

  prepAlert = (type) => {
    const { currentStatement } = this.state;
    let result;

    if (type === this.state.currentStatement.type) {
      result = correctSound
    } else result = incorrectSound

    let decideAnswer = type === currentStatement.type
      ? currentStatement.pos.answer
      : currentStatement.neg.answer;

    let decideFeedback = type === currentStatement.type
      ? currentStatement.pos.explo
      : currentStatement.neg.explo;

    this.setState({
      showAlert: !this.state.showAlert,
      decideFeedback: decideFeedback,
      decideAnswer: decideAnswer,
      feedbackAudio: result,
      playAudio: true
    });
  }

  fireAlert = (instruct, body) => {
    const { showAlert, decideFeedback, decideAnswer, showAnother } = this.state;
    return (
      <Alert
        showAlert={showAlert}
        text={decideAnswer}
        subText={decideFeedback}
        onClose={() => this.alertClose(showAnother, this.start)}
      />
    )
  }

  showNextButton = () => {
    setTimeout(() => {
      this.setState({ showNext: !this.state.showNext, showWrapUp: !this.state.showWrapUp })
    }, 750)
  }

  historyPusher = (path) => {
    this.props.history.push(path);
  }

  clickToss = (direction) => {
    const { sentFunc, sentTarget } = this.state;
    if (direction === 'right') {
      TweenLite.to(sentTarget, 0.6, {
        x: '500%',
        y: 50,
        rotation: 18,
        ease: Power3.easeOut,
        onComplete: sentTarget.empty,
        onCompleteScope: sentTarget
      });
      sentFunc(true);
    } else if (direction === 'left') {
      TweenLite.to(sentTarget, 0.6, {
        x: '-500%',
        y: 50,
        rotation: -18,
        ease: Power3.easeOut,
        onComplete: sentTarget.empty,
        onCompleteScope: sentTarget
      });
      sentFunc(false);
    } else {
      return
    }
  }

  loadShooter = (target, feedbackFunction) => {
    this.setState({
      sentTarget: target,
      sentFunc: feedbackFunction
    })
  }


  render() {
    return (
      <Consumer>
        {(context) => (
          <div>
            <PageProgBar pageTitle="Interactive Activity" />
            <Alert
              showAlert={this.state.showWrapUp}
              text="What did you learn about choosing activities?"
              subText={this.state.data.conclusion}
              buttonText="OK"
              onClose={() => {
                this.setState({ showWrapUp: !this.state.showWrapUp })
                this.props.history.push(context.goNextPage(context.state.currentModule));
                if (context.state[`module${context.state.currentModule}status`] !== 2) {
                  if (!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress();
                }
              }}
            />
            <div className="act-container">
              {this.state.activityStarted ? this.textIn(this.state.currentStatement.statement, this.state.currentStatement.image) : null}
            </div>
            {this.fireAlert()}
            <button
              className="click-swipe-btn-left"
              onClick={() => {
                this.clickToss('left')
              }}
            >
              <BackIcon />
            </button>
            <button
              className="click-swipe-btn-right"
              onClick={() => {
                this.clickToss('right')
              }}
            >
              <NextIcon />
            </button>
            <div className="left-swipe-instruct">
              <p>BAD ACTIVITY</p>
            </div>
            <div className="right-swipe-instruct">
              <p>GOOD ACTIVITY</p>
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}
Mod3Page2.contextType = Context;
export default Mod3Page2;