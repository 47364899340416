import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom';
import data from '../../../assets/json/core_content.json';
import { ReactComponent as ExpandIcon } from '../../../assets/images/next.svg'
import { Consumer, Context } from '../../../context';


class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      hovering: false,
      rotated: false
    }
    this.menu = null;
  }
  //
  handleHover = () => {
    this.setState(prevState => ({
      hovering: !prevState.hovering,
      rotated: !prevState.rotated
    }))
  }
  //
  createMenu = () => {
    const { rotated, hovering } = this.state;
    const list = this.state.data.menuList.map((item, i) => {
      if (i === 2) {
        return (
          <Fragment key={i}>
            <div
              className={`menu-link link-${i + 1}`}
              key={i}
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleHover}
            >
              <NavLink to={item.path} tabIndex="-1">{item.name}</NavLink>
              <div className={rotated ? 'arrow-icon rotate' : 'arrow-icon'}>
                <ExpandIcon />
              </div>
            </div>
            <div
              className={hovering ? 'sub-menu revealed' : 'sub-menu'}
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleHover}
            >
              {this.createSubMenu()}
            </div>
          </Fragment>
        )
      } else {
        return (
          <Consumer key={i}>
            {(context) => (
              <div className={`menu-link link-${i + 1}`} key={i} onClick={() => {
                context.toggleMenu();
                context.state.toggleFunc();
                if (item.name === "Sign Out") {
                  context.signOut();
                }
              }}>
                <NavLink to={item.path} tabIndex="-1">{item.name}</NavLink>
              </div>
            )}
          </Consumer>
        )
      }
    })
    return list;
  }
  //
  createSubMenu = () => {
    const subList = this.state.data.modules.map((item, i) => {
      return (
        <div className="sub-link" key={i}>
          <NavLink
            to={`/mod-${i + 1}-results`}
            className={this.context.state.unlockedMods.includes(i + 1) ? 'unlocked-link' : null}
            onClick={() => {
              this.context.toggleMenu();
              this.context.state.toggleFunc();
            }}
          >{item.name}
          </NavLink>
        </div>
      )
    })
    return subList;
  }

  //
  render() {
    const { showStatus } = this.props;
    return (
      <Fragment>
        <div className={`menu-panel ${showStatus}`}>
          {this.createMenu()}
        </div>
      </Fragment>
    )
  }
}
Menu.contextType = Context;
export default Menu
