import React, { Component, Fragment } from 'react'
import { Consumer, Context } from '../../../context';
import data from '../../../assets/json/comm_scenarios_act.json';
import Button from '../../shared/Button';
import Alert from '../../shared/Alert';
import PageProgBar from '../../shared/PageProgBar';
import CheckBox from '../../shared/CheckBox';
import BackendService from '../../../utils/BackendService';

class Mod4Page5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      promptsShowing: false,
      showAlert: false,
      buttonDisabled: true,
      showNote: false,
      activeScene: null,
      currentPrompts: [],
      isChecked: false,
      other: '',
      currentOther: '',
      showInstructions: false
    }
    this.saveData = []
  }

  componentDidMount() {
    this.state.data.scenarios.map((scenario, i) => {
      return this.setState({
        [`scenario-${i + 1}-chosen`]: false,
        [`custom-other-${i + 1}`]: ''
      })
    })
    setTimeout(() => {
      this.setState({ showInstructions: !this.state.showInstructions })
    }, 1500)
  }

  onScenarioClick = (e) => {
    this.setState({
      [`scenario-${e.target.dataset.scenario}-chosen`]: !this.state[`scenario-${e.target.dataset.scenario}-chosen`],
      buttonDisabled: true,
      activeScene: e.target.dataset.scenario
    })
  }

  showPrompts = (i) => {
    this.setState({
      [`scenario-${i + 1}-questions-showing`]: true
    })
  }

  toggleAlert = () => {
    this.setState({
      showAlert: !this.state.showAlert,
      buttonDisabled: false
    })
  }

  storePrompts = (e) => {
    if (this.state.currentPrompts.includes(e.target.value)) {
      this.setState({
        currentPrompts: this.state.currentPrompts.filter((prompt) => {
          return prompt !== e.target.value
        })
      }, () => this.setState({
        [`scenario-${this.state.activeScene}-prompts-to-store`]: this.state.currentPrompts
      }))
    } else {
      this.setState({
        currentPrompts: [...this.state.currentPrompts, e.target.value]
      }, () => this.setState({
        [`scenario-${this.state.activeScene}-prompts-to-store`]: this.state.currentPrompts
      }))
    }
  }

  saveToLocalStorage = (active, prompts, other) => {
    let obj = {
      'activeScene': active,
      'prompts': prompts,
      'other': other
    }
    this.saveData.push(obj);
    this.saveData.forEach((data) => {
      if (data.activeScene !== active) {
        this.saveData.push(obj)
      } else if (data.activeScene === active) {
        this.saveData = this.saveData.filter((obj) => {
          return obj.activeScene !== active
        });
        this.saveData.push(obj);
      }
    })
    this.saveData = this.saveData.sort((a, b) => {
      return a.activeScene - b.activeScene
    })
    this.context.setItem('scenarioData', JSON.stringify(this.saveData));
    // localStorage.setItem('scenarioData', JSON.stringify(this.saveData))
  }

  resetPromptArray = () => {
    this.setState({
      currentPrompts: []
    })
  }

  toggleChecked = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name]
    })
  }

  createQuestionList = (index) => {
    if (this.state.data.scenarios[index].questions) {
      const questions = this.state.data.scenarios[index].questions.map((question, i) => {
        return (
          <CheckBox
            key={i + 1}
            dataAttr={`check-${i + 1}`}
            checkContainerClass="check-boxes"
            labelName={question.text}
            labelValue={question.text}
            labelText={question.text}
            onChange={(e) => {
              this.storePrompts(e)
              this.toggleChecked(e)
            }}
            checked={this.state[question.text] ? true : false}
          />
        )
      })
      return questions;
    } else {
      return null;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
  }

  onInputChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  showInstructions = () => {
    return (
      <Alert
        showAlert={this.state.showInstructions}
        text="Try out some of your communication skills."
        subText={this.state.data.instruct}
        buttonText="OK"
        onClose={() => this.setState({ showInstructions: !this.state.showInstructions })}
      />
    )
  }

  backToScenarios = (i) => {
    this.setState({ [`scenario-${i + 1}-chosen`]: false })
  }

  buildScenarioList = () => {
    const scenarios = this.state.data.scenarios.map((scenario, i) => {
      return (
        <div
          key={i}
          className={this.state[`scenario-${i + 1}-chosen`] ? "scenario-card active" : this.state[`scenario-${i + 1}-highlight`] ? "scenario-card highlight" : 'scenario-card'}
          onClick={!this.state[`scenario-${i + 1}-chosen`] ? this.onScenarioClick : null}
          data-scenario={i + 1}
        >
          {!this.state[`scenario-${i + 1}-chosen`]
            ? scenario.title
            :
            <div>
              <Alert
                showAlert={this.state.showAlert}
                text="Your selections have been saved."
                // subText={`Please Visit The Module ${this.context.state.currentModule} Bookmarks Page To Review`}
                buttonText="Next Scenario"
                onClose={() => this.setState({
                  showAlert: false
                }, () => {
                  setTimeout(() => {
                    this.setState({
                      [`scenario-${i + 1}-chosen`]: false,
                      [`scenario-${i + 1}-highlight`]: true
                    })
                    this.saveToLocalStorage(this.state.activeScene, this.state[`scenario-${this.state.activeScene}-prompts-to-store`], this.state[`custom-other-${i + 1}`])
                    this.resetPromptArray()
                  }, 600)
                })}
              />
              <h1>{scenario.title}</h1>
              <p>{scenario.scenario}</p>
              <div className={this.state[`scenario-${i + 1}-questions-showing`] ? "prompt-form" : "prompt-form hidden"}>
                <br />
                <form onSubmit={this.handleSubmit}>
                  {this.createQuestionList(i)}
                  <label id="other-field-label" htmlFor="other-field">If you said something that is NOT listed, please feel free to type in your response in the box below:</label>
                  <input id="other-field" type="text" name={`custom-other-${i + 1}`} value={this.state[`custom-other-${i + 1}`]} onChange={(e) => this.onInputChange(e)} />
                </form>
                <p><span className="bold">Here's an idea for what to do OR say: </span>{scenario.example}</p>
              </div>
              <div className="button-box">
                <Button
                  text="Back"
                  icon="back"
                  onClick={() => {
                    this.backToScenarios(i)
                  }}
                />
                <Button
                  text="Continue"
                  icon="next"
                  onClick={() => {
                    if (!this.state[`scenario-${i + 1}-questions-showing`]) {
                      this.showPrompts(i);
                    } else this.toggleAlert();
                  }}
                />
              </div>
            </div>
          }
        </div>
      )
    })
    return scenarios;
  }

  render() {
    return (
      <Consumer>
        {(context) => (
          <Fragment>
            <PageProgBar pageTitle="Scenarios" />
            <div className="page-container">
              {/* <div className="instruct-txt">
                <p>{data.instruct}</p>
              </div> */}
              {this.showInstructions()}
              <div className="scenarios">
                {this.buildScenarioList()}
              </div>
              <div className="button-box">
                <Button
                  text="Next"
                  icon="next"
                  onClick={() => {
                    context.goNextPage(context.state.currentModule) === undefined ? this.props.history.push('/mod-4-conclude') : context.goNextPage(context.state.currentModule);
                    if (context.state[`module${context.state.currentModule}status`] !== 2) {
                      if (!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress(context.state.currentModule, 2, 5);
                    }
                  }}
                />
              </div>
            </div>
          </Fragment>
        )}
      </Consumer>
    )
  }
}
Mod4Page5.contextType = Context;
export default Mod4Page5;
