import React, { Component } from 'react';
import Button from '../../shared/Button';
import PageProgBar from '../../shared/PageProgBar';
import data from '../../../assets/json/m3_overview.json';
import { Consumer } from '../../../context';
import strings from '../../../assets/Language/module1';

class Mod1Page1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data
    }
  }

  render() {
    return (
      <Consumer>
        {(context) => (
          <div>
            <PageProgBar pageTitle="Introduction" />
            <div className="overview">
              <div className="intro-txt">
                <p>
                  {strings.introText}
                </p>
                <p>Living with an Interlock presents challenges for the whole family.  In this first module, you will learn about some of the common challenges people face with an Interlock and some tips to handle these situations.</p>
                <br />
                <p>Click <span className="bold">Next</span> to get started.</p>
              </div>
              <div className="button-box">
                <Button
                  text="Next"
                  icon="next"
                  onClick={() => {
                    this.props.history.push(context.goNextPage(context.state.currentModule));
                    if (context.state[`module${context.state.currentModule}status`] !== 2) {
                      if (!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}

export default Mod1Page1;