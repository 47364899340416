import React, { Component } from 'react'
import Button from '../../shared/Button';

class Mod4Conclude extends Component {
  render() {
    return (
      <div className="inner-page-content conclusion">
        <h1 className="conclude-header">What Did You Learn?</h1>
        <div className="conclude-txt">
          <p>You’ve learned about good and bad communication skills, and had a chance to practice. To use these skills, you need to THINK BEFORE YOU SPEAK. Here are the 7 “Do’s” of good communication:</p>
        </div>
        <ol className="conclude-points">
          <li>Use positive nonverbal behavior.</li>
          <li>Be polite.</li>
          <li>Choose the right place and time to bring up a problem.</li>
          <li>Share your feelings.</li>
          <li>Try to support and understand your family member's feelings.</li>
          <li>Ask for change in a positive manner.</li>
          <li>Be brief and stick to the point.</li>
        </ol>
        <div className="button-box">
          <Button
            text="Next" 
            icon="next"
            onClick={() => {
              this.props.history.push('/mod-4-results');
            }}
          />
        </div>
      </div>
    )
  }
}
export default Mod4Conclude;