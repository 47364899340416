import React, { Component } from 'react';
import styles from './Header.module.scss';
import { TweenMax } from 'gsap';
import BurgerMenu from '../BurgerMenu';
import Help from '../HelpMenu';
import Menu from '../Menu';
import { Consumer } from '../../../context';
import { ReactComponent as BSmartLogo } from '../../../assets/images/bsmart_logo.svg';
import { ReactComponent as SeListoLogo } from '../../../assets/images/se_listo_logo.svg';
import { NavLink } from 'react-router-dom';


class Header extends Component{
  constructor(props){
    super(props);
    this.state = {
      helpShow: false
    }
    this.header = null
  }

  componentDidMount(){
    TweenMax.from(this.header, 1, { autoAlpha: 0, delay: .3 });
    const localize = window.Localize;
    localize.on('setLanguage', (data) => {
      this.toggleLogos(data);
    });
  }

  toggleLogos = (data) => {
    if(data.to === 'en'){
      this.setState({ logo: 'english' })
    } else {   
      this.setState({ logo: 'spanish' })
    }
  }

  toggleHelp = () => {
    this.setState({ helpShow: !this.state.helpShow })
  }

  render(){
    const { container, logo, icons, helpIcon, burgCont } = styles;
    return(
      <Consumer>
        {(context) => (
          <div className={container} ref={header => this.header = header}>
            <div className={logo}>
             
          <NavLink to = {'/home'}>
              {this.state.logo === 'english' ? <BSmartLogo /> : <SeListoLogo />}
          </NavLink>
         
            </div>
            <div className={icons}>
            <div>
                <div 
                  className={helpIcon}
                  onClick={this.toggleHelp}
              ><p>?</p></div>
              </div>
              <div className={burgCont}>
                <BurgerMenu menuStatus={context.state.menuShow}/>
              </div>
            </div>
            <Menu showStatus={context.state.menuShow ? 'open' : 'closed' } />
            <Help 
              helpShow={this.state.helpShow} 
              toggleHelp={this.toggleHelp}
              currentPage={window.location.pathname}
            />
          </div>
        )}
      </Consumer>
    )
  }
}
export default Header;
