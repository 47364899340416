import React, { Component } from 'react'
import Button from '../../shared/Button';
import { Consumer } from '../../../context';
import PageProgBar from '../../shared/PageProgBar';

class Mod3Page4 extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  render() {
    return (
      <Consumer>
        {(context) => (
          <div>
            <PageProgBar pageTitle="Local Resources" />
            <div className="instruct-txt-center">
              <p>List of Local Resources To Find Where to Do Things Together:</p>
              <br/>
              <p>Here is where you can find ideas for where you can do things together in Santa Fe:</p>
              <br/>
              <a target="_blank" rel="noopener noreferrer" href="https://www.santafenm.gov/museums_events_attractions">Click Here!</a>
              <br />
            </div>
            <div className="button-container">
              <Button 
                text="Next"
                icon="next"
                onClick={() => {
                  context.goNextPage(context.state.currentModule) === undefined ? this.props.history.push('/mod-3-conclude') : context.goNextPage(context.state.currentModule);
                  if(context.state[`module${context.state.currentModule}status`] !== 2){
                    if(!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress(context.state.currentModule, 2, 4);
                  }
                }}
              />
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}

export default Mod3Page4;