import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings ({
    en: {
       introText:  "Living with an Interlock presents challenges for the whole family.  In this first module, you will learn about some of the common challenges people face with an Interlock and some tips to handle these situations.",
        next: "CLick Next to get started",
    
    },
    es: {
        introText: "Viviendo con un persona con dificiles es muy  challengando por todo de los familia",
        next: "Clicko Nexto a continuar"
    }
})



export default {strings} ;