import React, { Component } from 'react'
import Button from '../../shared/Button';
import { Consumer } from '../../../context';
import PageProgBar from '../../shared/PageProgBar';
import VideoPlayer from '../../shared/VideoPlayer';
import { VIDEO_DOMAIN } from '../../../config';
//

class Mod3Page1 extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.videoContainer = null;
  }

  componentDidMount() {
    const _Localize = window.Localize;
    let currentLanguage = _Localize.getLanguage();
    this.setState({ language: currentLanguage })
  }

  logStuff(x) {
    console.log(x)
  }


  render() {
    return (
      <Consumer>
        {(context) => (

          <div>
            <PageProgBar pageTitle="Video" />
            <div className="inner-page-content">
              <div className="col-page">
                <div className="content-left">
                  <p className="vid-summary">In this module, you will get ideas for things you can do with your family member that don't involve alcohol.</p>
                  <ul>
                    <li>Watch this video clip.</li>
                    <li>Can you relate to the situation?</li>
                    <li>When you are finished watching the video, please click <span className="bold">Next</span>.</li>
                  </ul>
                  <Button
                    text='Subtitles'
                    icon='info'
                    onClick={() => {
                      context.subtitlesToggle()
                    }}
                  />
                </div>
                <div className="content-right">
                  <div className="video-player-container" ref={videoContainer => this.videoContainer = videoContainer}>

                    <VideoPlayer
                      subtitleStatus={context.state.subtitles} subtitle={this.state.language === 'en' ? 'dtt_en.txt' : 'dtt_es.txt'} source={this.state.language === 'en' ? `${VIDEO_DOMAIN}dtt_en.mp4` : `${VIDEO_DOMAIN}dtt_es.mp4`}
                    />

                  </div>
                </div>
              </div>
              <div className="button-box">
                <Button
                  text="Next"
                  icon="next"
                  onClick={() => {
                    this.props.history.push(context.goNextPage(context.state.currentModule));
                    if (context.state[`module${context.state.currentModule}status`] !== 2) {
                      if (!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress();
                    }

                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}

export default Mod3Page1;