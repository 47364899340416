import React, { Component } from 'react'
import { Consumer } from '../../../context';
import data from '../../../assets/json/skills_video_act.json';

import PageProgBar from '../../shared/PageProgBar';
import Button from '../../shared/Button';
import Alert from '../../shared/Alert';

class Mod4Page1 extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: data,
      buttonDisabled: true,
      showAlert: false,
      alertText: data.instruct,
      showAnotherAlert: false
    }
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({ showAlert: true })
    }, 1500)
  }

  onAlertClose = () => {
    this.setState({ showAlert: false, buttonDisabled: false }, () => {
      setTimeout(() => {
        if(this.state.showAnotherAlert){
          this.setState({ 
            showAlert: true, 
            alertText: data.instruct2, 
            showAnotherAlert: false, 
            buttonDisabled: false 
          })
        }
      }, 1000)
    })
  }

  fireAlert = () => {
    return(
      <Alert 
        showAlert={this.state.showAlert} 
        subText={this.state.alertText}
        onClose={this.onAlertClose}
        buttonText="Next"
        allowDanger={true}
      />
    )
  }

  render() {
    const { data, buttonDisabled } = this.state;
    return (
      <Consumer>
        {(context) => (
          <div className="vid-act-container">
            <PageProgBar pageTitle="Dos and Don'ts of Communication" />
            <div className="overview">
              <div className="intro-txt">
                <p dangerouslySetInnerHTML={{ __html: data.intro_text }}></p>
              </div>
            </div>
            {this.fireAlert()}
            <div className="button-container">
              <Button 
                text="Next"
                icon="next"
                disabled={buttonDisabled}
                onClick={() => {
                  this.props.history.push(context.goNextPage(context.state.currentModule));
                  if(context.state[`module${context.state.currentModule}status`] !== 2){
                    if(!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress();
                  }
                }}  
              />
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}


export default Mod4Page1;