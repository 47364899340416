import React, { Component } from 'react'
import styles from './VideoPlayer.module.scss';
import { TweenLite, Back } from 'gsap';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false
    }
    this.video = null;
    this.hotspot = null;
    this.bigVidBtn = null;
    this.playerContainer = null;
  }

  togglePlayBtn = () => {
    this.setState({
      isPlaying: !this.state.isPlaying
    })
  }

  onVideoPlay = () => {
    this.togglePlayBtn();
    TweenLite.to(this.hotspot, .7, { css: { backgroundColor: 'none' } })
    TweenLite.to(this.bigVidBtn, .7, { autoAlpha: 0, scale: 0.5, ease: Back.easeInOut })
  }

  onVideoPause = () => {
    this.togglePlayBtn();
    TweenLite.to(this.hotspot, .7, { css: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } })
    TweenLite.to(this.bigVidBtn, .7, { autoAlpha: 1, scale: 1, ease: Back.easeInOut })
  }

  blacken = () => {
    // let activeHeight = this.video.getBoundingClientRect().height;
    TweenLite.to(this.video, .01, { css: { backgroundColor: 'black', height: '430px' } })
    TweenLite.to(this.hotspot, .1, {
      css: { backgroundColor: 'rgba(0, 0, 0, 1)' }, onComplete: () => {
        TweenLite.to(this.playerContainer, .1, { css: { backgroundColor: 'black' } })
      }
    })
  }

  toggleVidPlay = () => {
    this.state.isPlaying ? this.video.pause() : this.video.play();
  }

  decideButton = () => {
    if (this.state.firstPlayDone) {
      this.setState({ firstPlayDone: false })
    } else return;
  }

  render() {
    return (
      <div className={`${styles.playerContainer}`} ref={c => this.playerContainer = c}>
        <video
          playsInline
          preload="auto"
          ref={video => this.video = video}
          onEnded={() => {
            // this.blacken();
            if (this.props.onEnded) this.props.onEnded();
            this.setState({ firstPlayDone: true })
          }}
          onPause={this.onVideoPause}
          onPlay={this.onVideoPlay}
        >
          <source src={this.props.source} type="video/mp4"></source>
          <source src={this.props.altSource} type="video/webm"></source>
          {this.props.subtitle && this.props.subtitleStatus ?
            <track kind='subtitles' srcLang="en" default src={require(`../../../assets/vtt/${this.props.subtitle}`)} /> :
            <></>
          }
          <p>This Broswer Does Not Support Video...</p>
        </video>
        <div ref={hotspot => this.hotspot = hotspot} className={styles.hotspot} onClick={() => {
          this.toggleVidPlay();
          this.decideButton();
        }}>
          <div
            ref={bigVidBtn => this.bigVidBtn = bigVidBtn}
            className={`${styles.bigCtrlBtn} ${this.state.isPlaying ? styles.paused : this.state.firstPlayDone ? styles.replay : styles.play}`}>
          </div>
        </div>
      </div>
    )
  }
}
export default VideoPlayer;