import React, { Component } from 'react'
import content from '../../assets/json/core_info.json';

class About extends Component {
  constructor(props){
    super(props);
    this.state = {
      content
    }
  }


  render() {
    const { content } = this.state;
    return (
      <div className="about-container">
        <h1>About</h1>
        <p>{content.about_text}</p>
      </div>
    )
  }
}

export default About;