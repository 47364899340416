import React from 'react';
import SwipeData from '../../../assets/json/m2_swiper.json';
import ImageLoader from '../SwipeCard/imageLoader';
function DataPreload(props) {
  const fetchVideo = async (link) => {
    const req = new XMLHttpRequest();
    req.open('GET', link, true);
    req.responseType = 'blob';

    req.onload = function () {
      if (this.status === 200) {
        let videoBlob = this.response;
        let vid = URL.createObjectURL(videoBlob)
      }
    }
  }
  return (<>
    <div className='imageLoader'>
      {
        SwipeData.statements.map((item, index) => {
          return (
            <>
              <ImageLoader
                img={item.image}
              />
            </>)
        })
      }
      {fetchVideo}

    </div>
  </>)
}

export default DataPreload