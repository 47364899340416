import React, { Component, Fragment } from 'react';
import Button from '../shared/Button';
import { Consumer, Context } from '../../context';
import LoginService from '../../utils/LoginService';
import { withRouter } from 'react-router-dom';
import { TweenMax, Power1 } from 'gsap';
import LanguageWidget from '../shared/LanguageWidget';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      disabled: true
    }
    this.login = null;
  }

  componentDidMount() {
    TweenMax.from(this.login, 1, { autoAlpha: 0, ease: Power1.easeInOut });
    if (LoginService.isLoggedIn()) {
      window.location = '/home'
    }
  }

  submitForm = async (e) => {
    e.preventDefault();
    if (!this.state.disabled) {
      // this is where the conditional for loggin in should be handled.
      if (await this.context.login(this.state.username, this.state.password)) {
        this.props.history.push(`/home`)

      }
      // this.context.login(this.state.username, this.state.password);
    }
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.username !== '' && this.state.password !== '') {
        this.setState({ disabled: false });
      } else if (this.state.username === '' || this.state.password === '') {
        this.setState({ disabled: true });
      }
    })
  }

  render() {
    const { username, password } = this.state
    return (
      <Fragment>
        <div className="login-container" ref={login => this.login = login}>
          <h1>Login</h1>
          {this.context.state.usernameFailed ?
            <div className='loginError'> User not found
            </div> :
            this.context.state.passwordFailed ?
              <div className='loginError'>
                Authentication error
              </div> :
              null
          }
          <form
            className="login-form"
            onSubmit={this.submitForm}>
            <label>Username:</label>
            <input
              type="text"
              name="username"
              onChange={this.onInputChange}
              value={username} />
            <label>Password:</label>
            <input
              type="password"
              name="password"
              onChange={this.onInputChange}
              value={password} />
            <div className="submit-button">
              <Button
                text="Submit"
                icon="check"
                color="iconHolder"
                disabled={this.state.disabled}
              />
            </div>
          </form>
        </div>
        <LanguageWidget />
      </Fragment>
    )
  }
}
Login.contextType = Context;
export default withRouter(Login);