import React from 'react';

function Triangle (props){
        return(
            <svg data-section = {props.data}viewBox="0 0 357 357">
            <polygon data-section = {props.data} points="38.25,0 38.25,357 318.75,178.5"/>
        </svg> 
        )
    
}
export default Triangle



