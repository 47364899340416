import React from 'react';
import styles from './Button.module.scss';
import { ReactComponent as CheckIcon } from '../../../assets/svgs/check.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svgs/close.svg';
import { ReactComponent as EditIcon } from '../../../assets/svgs/pencil.svg';
import { ReactComponent as InfoIcon } from '../../../assets/svgs/info.svg';
import { ReactComponent as NextIcon } from '../../../assets/svgs/next.svg';
import { ReactComponent as AddIcon } from '../../../assets/svgs/add.svg';
import { ReactComponent as BackIcon } from '../../../assets/svgs/back.svg';
import { ReactComponent as ReplayIcon } from '../../../assets/svgs/replay.svg';

//HELPERS//

const createIcon = (icon) => {
  switch(icon){
    case 'check':
      icon = <CheckIcon />
      break;
    case 'close':
      icon = <CloseIcon />
      break;
    case 'edit':
      icon = <EditIcon />
      break;
    case 'info':
      icon = <InfoIcon />
      break;
    case 'next':
      icon = <NextIcon />
      break;
    case 'add':
      icon = <AddIcon />
      break;
    case 'back':
      icon = <BackIcon />
      break;
    case 'replay':
      icon = <ReplayIcon />
      break;
    default:
      break;
  }
  return icon; 
}

// COMPONENT //

const Button = (props) => {
  const { text, color, onClick } = props;
  const { iconSpan, iconHolder, textSpan, bsBtn, disabled, altColor } = styles
  return(
    <button 
      className={`${bsBtn} ${styles[color]} ${props.altColor === true ? altColor : null} ${props.disabled ? disabled : null}`}
      onClick={onClick}
    >
      <span className={iconSpan}>
        <div className={iconHolder}>
          {createIcon(props.icon)}
        </div>
      </span>
      <span className={textSpan}>{text}</span>
    </button>
  )
}
export default Button;

