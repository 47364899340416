import React, { Component } from 'react'
import Button from '../../shared/Button';
import { Consumer, Context } from '../../../context';
import PageProgBar from '../../shared/PageProgBar';
import Alert from '../../shared/Alert';
import data from '../../../assets/json/activity_sorter.json';
import { TimelineLite, Back } from 'gsap';
import BackendService from '../../../utils/BackendService';


class Mod3Page3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      flaggedArr: [],
      chosenActs: [],
      showAlert: false,
      showNext: false,
      showInstructions: false,
      customAct: ''
    }
  }

  componentDidMount() {
    this.state.data.tag_titles.map((item, i) => {
      return this.setState({ [`${item.attr}-tag-chosen`]: false })
    })
    this.state.data.activities.map((act, i) => {
      return this.setState({ [`act-${act.id}-toggle`]: false })
    })
    setTimeout(() => {
      let tl = new TimelineLite({
        onComplete: () => {
          this.setState({ showInstructions: !this.state.showInstructions })
        }
      });
      tl.staggerTo('.activity-item', 1, { y: 0, scale: 1, autoAlpha: 1, ease: Back.easeInOut }, 0.05);
    }, 1000)
  }

  createList = () => {
    const acts = this.state.data.activities.map((act, i) => {
      let tagArr = act.tags;
      if (0 === act.tags.length) return false;
      if (this.checkContainTag(tagArr, this.state.flaggedArr) === true) {
        return (
          <div
            key={i}
            data-act={act.id}
            className={this.state[`act-${act.id}-toggle`] ? "activity-item act-chosen" : "activity-item"}
            onClick={this.toggleActSelected}
          >
            <p>{act.name}</p>
          </div>
        )
      } else {
        return (
          <div key={i} data-act={act.id} className="activity-item hidden-tag">
            <p>{act.name}</p>
          </div>
        )
      }
    })
    return acts;
  }

  toggleActSelected = (e) => {
    if (this.state.chosenActs.includes(e.target.innerText)) {
      this.setState({
        [`act-${e.target.dataset.act}-toggle`]: !this.state[`act-${e.target.dataset.act}-toggle`],
        chosenActs: this.state.chosenActs.filter((act) => {
          return act !== e.target.innerText;
        })
      })
    } else {
      this.setState({
        [`act-${e.target.dataset.act}-toggle`]: !this.state[`act-${e.target.dataset.act}-toggle`],
        chosenActs: [...this.state.chosenActs, e.target.innerText]
      })
    }
  }

  checkContainTag = (superset, subset) => {
    if (0 === subset.length) {
      return true;
    }
    return subset.every(function (value) {
      return (superset.indexOf(value) >= 0);
    });
  }

  toggleTag = (e) => {
    if (this.state.flaggedArr.includes(e.target.dataset.tag)) {
      this.setState({
        [`${e.target.dataset.tag}-tag-chosen`]: !this.state[`${e.target.dataset.tag}-tag-chosen`],
        flaggedArr: this.state.flaggedArr.filter((tag) => {
          return tag !== e.target.dataset.tag;
        })
      })
    } else {
      this.setState({
        [`${e.target.dataset.tag}-tag-chosen`]: !this.state[`${e.target.dataset.tag}-tag-chosen`],
        flaggedArr: [...this.state.flaggedArr, e.target.dataset.tag]
      })
    }
  }

  createTagBtns = () => {
    const tags = this.state.data.tag_titles.map((tag, i) => {
      return (
        <div
          key={i}
          data-tag={tag.attr}
          className={this.state[`${tag.attr}-tag-chosen`] === true ? "topic-btn active-tag" : "topic-btn"}
          onClick={this.toggleTag}>{tag.title}
        </div>
      )
    })
    return tags;
  }

  saveActivities = () => {
    let acts = this.state.chosenActs;
    if (this.state.customAct !== '') acts.push(this.state.customAct);
    // localStorage.setItem('savedActs', JSON.stringify(acts));
    this.context.setItem('savedActs', JSON.stringify(acts));
    this.setState({ showAlert: !this.state.showAlert })
  }

  alertClose = () => {
    this.setState({ showAlert: !this.state.showAlert, showNext: !this.state.showNext })
  }

  showInstructions = () => {
    return (
      <Alert
        showAlert={this.state.showInstructions}
        text="Create a list of activities."
        subText={this.state.data.instruct}
        buttonText="Continue"
        onClose={() => this.setState({ showInstructions: !this.state.showInstructions })}
      />
    )
  }

  historyPusher = (path) => {
    this.props.history.push(path);
  }

  handleCustomChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <Consumer>
        {(context) => (
          <div>
            <PageProgBar pageTitle="Creating Activity List" />
            <Alert
              showAlert={this.state.showAlert}
              text={this.state.chosenActs.length === 0 ? "Please choose some activities..." : "Your ideas have been saved."}
              buttonText="OK"
              onClose={() => {
                this.alertClose()
                context.goNextPage(context.state.currentModule) === undefined
                  ? this.props.history.push('/mod-3-conclude')
                  : context.goNextPage(context.state.currentModule);
                if (context.state[`module${context.state.currentModule}status`] !== 2) {
                  if (!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress(context.state.currentModule, 2, 4) // (current mod), (status to be applied), (target for unlock)
                }
              }}
            />
            {this.showInstructions()}
            <div className="main-act-wrap">
              <div className="topic-btns">
                {this.createTagBtns()}
              </div>
              <div className="act-list">
                {this.createList()}
              </div>

            </div>

            <div className="custom-act-input">
              <h3>Feel free to type in an activity that isn't listed above.</h3>
              <br />
              <input
                type="text"
                name="customAct"
                className="gridInput"
                value={this.state.customAct}
                onChange={this.handleCustomChange}
              />
            </div>
            <div className="button-container">
              <Button
                text="Save To Bookmarks"
                onClick={this.saveActivities}
                icon="edit"
              />
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}
Mod3Page3.contextType = Context;
export default Mod3Page3;