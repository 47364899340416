import React, { Component } from 'react'
import Button from '../../shared/Button';

class Mod2Remind extends Component {
  constructor(props){
    super(props);
    this.state = {
      frequency: 'DAILY',
      time: '1:00',
      action: 'Do one of your supportive actions.',
      am_pm: 'AM'
    }
    this._CALENDAR = window.ics();
  }
  

  generateTodaysDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    return today;
  }

  handleSelectChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  isIos = () => {
    var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    return iOS;
  }

  createEventFile = () => { 
    if (this.isIos()) { 
      window.open("data:text/calendar;charset=utf8," + escape(this._CALENDAR.build())); 
    } else { 
      this._CALENDAR.download('bsmart-calendar-event'); 
    }
  }


  handleSubmitReminder = (e) => {
    e.preventDefault();
    const { action, time, frequency, am_pm } = this.state;
    let startDate = this.generateTodaysDate();
    let start = startDate + ' ' + time + ' ' + am_pm;

    this._CALENDAR.addEvent(action, action, '', start, start, { rrule: `RRULE:FREQ=${frequency}` }); // (subject, description, location, begin, end, rrule)
    this.createEventFile();
  }

  render() {
    return (
      <div className="page-content">
        <div className="inner-page-content">
          <div className="header-row">
            <h1>Add a Reminder</h1>
            <div className="button-box">
              <Button 
                text="Back"
                icon="back"
                onClick={() => this.props.history.goBack()}
              />
            </div>
          </div>
          <p>Use this form to set a reminder to be supportive to your family member. A calendar event will be created once you click "Add to Calendar."</p>
        </div>
        <div className="reminder-form-wrap">
          <form onSubmit={this.handleSubmitReminder}>
            <label>Frequency:</label>
            <select name="frequency" value={this.state.frequency} onChange={this.handleSelectChange}>
              <option value="DAILY">Daily</option>
              <option value="WEEKLY">Weekly</option>
              <option value="MONTHLY">Monthly</option>
            </select>
            <label>Time:</label>
            <div className="time-wrap">
              <select name="time" value={this.state.time} onChange={this.handleSelectChange}>
                <option>1:00</option>
                <option>1:30</option>
                <option>2:00</option>
                <option>2:30</option>
                <option>3:00</option>
                <option>3:30</option>
                <option>4:00</option>
                <option>4:30</option>
                <option>5:00</option>
                <option>5:30</option>
                <option>6:00</option>
                <option>6:30</option>
                <option>7:00</option>
                <option>7:30</option>
                <option>8:00</option>
                <option>8:30</option>
                <option>9:00</option>
                <option>9:30</option>
                <option>10:00</option>
                <option>10:30</option>
                <option>11:00</option>
                <option>11:30</option>
                <option>12:00</option>
                <option>12:30</option>
              </select>
              <select name="am_pm" value={this.state.am_pm} onChange={this.handleSelectChange}>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
            <label>Action:</label>
            <select name="action" value={this.state.action} onChange={this.handleSelectChange}>
              <option value="Do one of your supportive actions.">Do one of your supportive actions.</option>
              <option value="Say one of your supportive phrases.">Say one of your supportive phrases.</option>
              <option value="Use one of your supportive words.">Use one of your supportive words.</option>
              <option value="Plan one of your supportive activities.">Plan one of your supportive activities.</option>
            </select>
            <Button 
              text="Add to Calendar"
              icon="add"
            />
          </form>
        </div>
      </div>
    )
  }
}
export default Mod2Remind;