import React from 'react'; 

function imageLoader (props) {
  return( <>
  <img src = {require(`../../../assets/images/swiper2/${props.img}`)}>

  </img>
  </>)
}

export default imageLoader