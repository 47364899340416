import React, { Component } from 'react'
import styles from './ProgressHolder.module.scss';
import { TweenMax } from 'gsap';
import { DrawSVGPlugin } from '../../../vendor/gsap/DrawSVGPlugin';
import data from '../../../assets/json/core_content.json';
import { Context, Consumer } from '../../../context';
import TweenLite from 'gsap/TweenLite';


class PageProgBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data
    }
    this.progBarRect = null;
    this.progBarStatus = null;
    this.container = null;
    this.recPath = [];
  }
  //
  componentDidMount() {
    TweenMax.from(this.container, .4, { y: -300, delay: 1 });
    TweenLite.delayedCall(0, () => {
      let lessonsCompleted = this.context.state[`module${this.context.state.currentModule}pages-completed`];
      let totalLessons = this.context.state[`module${this.context.state.currentModule}total`] - 1; //minus 1 because there are 1 less lines than squares/circles
      let percentCompleted = ((lessonsCompleted / totalLessons) * 100).toFixed(2);
      this.progressAnimSetter(lessonsCompleted);
      if (this.context.state.unlockedPages.includes(window.location.pathname)) {
        return
      } else {
        this.animateProgress(lessonsCompleted, percentCompleted);
      }
    })
  }
  //
  animateProgress = (lessonsCompleted, percentCompleted) => {
    TweenMax.delayedCall(2, () => {
      if (this.context.state[`module${this.context.state.currentModule}status`] !== 2) {
        TweenMax.to(this.recPath[lessonsCompleted], 2, { drawSVG: "100%", delay: .5 })
        TweenMax.to(this.progBarStatus, 1, { drawSVG: `${percentCompleted}%`, onComplete: this.context.updatePercents(this.context.state.currentModule, percentCompleted) });
      } else {
        return;
      }
    });
  }
  //
  progressAnimSetter = (lessonsCompleted) => {
    if (this.context.state[`module${this.context.state.currentModule}status`] === 2) {
      TweenMax.set(this.progBarStatus, { drawSVG: "100%" });
    } else {
      TweenMax.set(this.progBarStatus, { drawSVG: `${this.context.state[`module${this.context.state.currentModule}percent-completed`]}%` });
    }
    this.recPath.forEach((item, i) => {
      if (item) {
        if (this.context.state[`module${this.context.state.currentModule}status`] === 2) {
          return TweenMax.set(item, { drawSVG: "100%", fill: 'green' })
        }
        if (i < lessonsCompleted) {
          if (i === lessonsCompleted - 1) {
            return TweenMax.set(item, {
              drawSVG: "100%", onComplete: () => {
                TweenMax.to(item, 1, { fill: 'green', delay: 1.5 })
              }
            })
          } else {
            return TweenMax.set(item, { drawSVG: "100%", fill: 'green' })
          }
        } else {
          return TweenMax.set(item, { drawSVG: "50% 50%" })
        }
      }
    })
  }
  //
  createProgressBar = () => {
    const boxes = this.context.state.modules[this.context.state.currentModule - 1].pages.map((module, i) => {
      return (
        <svg id={styles.rec} key={i} viewBox="0 0 87 87" preserveAspectRatio="xMidYMid meet">
          <path className={styles.cls2} d="M80,39.86a40.32,40.32,0,1,0,0,.64Z" transform="translate(3.5 3)" />
          <path className={styles.cls} key={i} ref={recPath => this.recPath[i] = recPath} d="M80,39.86a40.32,40.32,0,1,0,0,.64Z" transform="translate(3.5 3)" />
        </svg>
      )
    })
    return boxes;
  }
  //
  render() {
    const { container, progressBar, bar, barLine, barLineColor, modTitle } = styles;
    return (
      <Consumer>
        {(context) => (
          <div className={container} ref={container => this.container = container}>
            <p className={modTitle}>{context.state.modules[context.state.currentModule - 1].name}: {this.props.pageTitle}</p>
            <div className={progressBar}>
              {this.createProgressBar()}
              <svg id={bar} viewBox="0 0 855 20">
                <line className={barLine} ref={progBarPath => this.progBarPath = progBarPath} y1="10" x2="855" y2="10" />
                <line className={barLineColor} ref={progBarStatus => this.progBarStatus = progBarStatus} y1="10" x2="855" y2="10" />
              </svg>
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}

PageProgBar.contextType = Context;
export default PageProgBar;
