import React, { Component } from 'react'
import { Consumer } from '../../../context';
import UseHolder from '../../shared/DragTextHolder';
import Alert from '../../shared/Alert';
import PageProgBar from '../../shared/PageProgBar';
import Button from '../../shared/Button';
import data from '../../../assets/json/drag_drop_box_act.json';

class Mod4Page4 extends Component {
  constructor(props){
    super(props);
    this.state = {
      showAlert: false,
      actStarted: false,
      statementIndex: 0,
      data: data,
      showRecap: false,
      sound: '',
      testerTicker: 0,
      firstAlert: true
    }
    this.container = null;
    this.targetRefs = [];
    this.lids = [];
    this.boxRef = null;
  }

  componentDidMount(){
    setTimeout(() => {
      this.showInstruct();
    }, 1000);
    this.addResizeFunc();
  }

  addResizeFunc = (onResize) => {
    window.addEventListener('resize', onResize)
  }

  removeResizeFunc = (onResize) => {
    window.removeEventListener('resize', onResize)
  }

  showInstruct = () => {
    this.setState({ showAlert: true });
  }

  start = () => {
    this.setState({ showAlert: false, actStarted: true, firstAlert: false });
  }

  setText = () => {
    if(this.state.statementIndex < this.state.data.statements.length -1){
      this.setState({
        statementIndex: this.state.statementIndex + 1
      }, () => console.log(this.state, 'setting text'))
    } else {
      this.setState({
        activityEnded: true
      })
      this.showReview()
    }
  }

  textIn = (text) => {
    return (
      <UseHolder 
        actStarted={this.state.actStarted}
        text={text}
        containerRef={this.container}
        targetRefs={this.targetRefs}
        current={this.state.data.statements[this.state.statementIndex]}
        lidRef={this.lids}
        setText={this.setText}
        activityStarted={this.state.actStarted}
        activityEnded={this.state.activityEnded}
        boxRef={this.boxRef}
        onResize={this.addResizeFunc}
        removeResize={this.removeResizeFunc}
      />
    )
  }

  historyPusher = (path) => {
    this.props.history.push(path);
  }


  showReview = () => {
    setTimeout(() => {
      this.setState({
        showRecap: true
      })
    }, 750)
  }

  createReview = (kind) => {
    const statements = this.state.data.statements
      .filter(statement => statement.type === kind)
      .map((statement, i) => {
        return <li key={i}>{statement.text}</li>
      })
      return statements;
  }

  render() {
    const { showAlert, actStarted, data, statementIndex, activityEnded, showRecap } = this.state;
    return (
      <Consumer>
        {(context) => (
          <div className="activity-container" ref={container => this.container = container}>
            <PageProgBar pageTitle="Follow-Up Activity" />
            <Alert 
              showAlert={showAlert}
              text="What went right and wrong?" 
              subText={!activityEnded ? data.instructions : 'What Did You Learn?'}
              onClose={this.start}
            />
            <Alert 
              showAlert={showRecap} 
              onClose={() => this.setState({ showRecap: false })}
            >
              <div className="recap-section">
              <div>
                  <h2>What Went <span className="bold"> Wrong:</span></h2>
                  <ul>
                    {this.createReview('wrong')} 
                  </ul> 
                </div>
                <div>
                  <h2>What Went <span className="bold"> Right:</span></h2>
                  <ul>
                    {this.createReview('right')}
                  </ul>
                </div>
              </div>
            </Alert>
            <div className="target-boxes">
              <div 
                ref={targetRef => this.targetRefs[0] = targetRef} 
                className="target-box"
                data-type="wrong"
              >
                <svg viewBox="0 -90 340 395.5">
                  <rect ref={boxRef => this.boxRef = boxRef} id="box-front" className="box-face" x="35" y="80.5" width="270" height="225"/>
                  <rect id="sticker" className="red-sticker" x="107" y="231" width="126" height="39"/>
                  <g id="Writing">
                      <rect className="writing" x="684" y="476" width="41" height="5" rx="2.5" transform="translate(907 736) rotate(180)"/>
                      <rect className="writing" x="662" y="476" width="16" height="5" rx="2.5" transform="translate(838 736) rotate(180)"/>
                      <rect className="writing" x="629" y="476" width="26" height="5" rx="2.5" transform="translate(782 736) rotate(180)"/>
                      <rect className="writing" x="618" y="476" width="5" height="5" rx="2.5" transform="translate(739 736) rotate(-180)"/>
                      <rect className="writing" x="218" y="242" width="5" height="5" rx="2.5"/>
                      <rect className="writing" x="186" y="242" width="26" height="5" rx="2.5"/>
                      <rect className="writing" x="163" y="242" width="16" height="5" rx="2.5"/>
                      <rect className="writing" x="116" y="242" width="41" height="5" rx="2.5"/>
                  </g>
                  <rect id="handle" className="box-handle" x="132" y="119" width="77" height="17" rx="6"/>
                  <g ref={lid => this.lids[0] = lid} id="lid">
                    <polygon className="lid-top" points="292.81 0 47.19 0 0 23 340 23 292.81 0"/>
                    <rect className="lid-front" y="23" width="340" height="58"/>
                    <g id="Label">
                      <g>
                        <path d="M622.12,278l-2.19,8.77H616l-5.83-21.3a3.78,3.78,0,0,0-.38-1,1.26,1.26,0,0,0-.59-.41,1,1,0,0,1-.72-1,.91.91,0,0,1,.45-.81,2.39,2.39,0,0,1,1.27-.28h8.48a1.86,1.86,0,0,1,1.09.22.85.85,0,0,1,.38.74,1.1,1.1,0,0,1-.63.94,2.44,2.44,0,0,0-.68.52,1.07,1.07,0,0,0-.16.62,1.34,1.34,0,0,0,.06.4Zm1.72-16h3.74l4.35,16.5-2.07,8.24h-4l-4.22-15.93Zm8.78,13.59-1-3.67,1.33-5.37a4.13,4.13,0,0,0,.16-1,1.48,1.48,0,0,0-.3-.93,2.83,2.83,0,0,0-1-.74,1.86,1.86,0,0,1-.62-.45.85.85,0,0,1-.17-.56.76.76,0,0,1,.29-.68,1.71,1.71,0,0,1,1-.21h4.31a1.88,1.88,0,0,1,1,.21.79.79,0,0,1,.3.7.76.76,0,0,1-.15.48,1.61,1.61,0,0,1-.52.35,4,4,0,0,0-1.2.76,5.33,5.33,0,0,0-1.25,2.41Z" transform="translate(-502 -221)"/>
                        <path d="M649.87,262v21.3a1.51,1.51,0,0,0,.27,1,.69.69,0,0,0,.28.23c.08,0,.36.11.83.23a1,1,0,0,1,.87,1,.89.89,0,0,1-.47.77,2.3,2.3,0,0,1-1.14.21h-9.18a1.76,1.76,0,0,1-1-.24.8.8,0,0,1-.35-.7,1,1,0,0,1,.72-1l.7-.24a.68.68,0,0,0,.21-.21,1.62,1.62,0,0,0,.21-.93V265.28a1.64,1.64,0,0,0-.21-.93.75.75,0,0,0-.21-.2c-.07,0-.3-.12-.7-.25a1,1,0,0,1-.72-1,.8.8,0,0,1,.35-.7,1.76,1.76,0,0,1,1-.24h8.54Zm5.57,12.62a15.19,15.19,0,0,1,2.56.56,5.9,5.9,0,0,1,1.62.83,3.92,3.92,0,0,1,1.17,1.34,3.42,3.42,0,0,1,.41,1.65v3.75c0,.39.13.58.38.58a.36.36,0,0,0,.25-.08,2.48,2.48,0,0,0,.28-.31,1.23,1.23,0,0,1,.92-.62.87.87,0,0,1,.62.33,1,1,0,0,1,.31.65,2.65,2.65,0,0,1-.54,1.37,6,6,0,0,1-1.33,1.44,4.83,4.83,0,0,1-1.61.86,7.35,7.35,0,0,1-2.05.26,5,5,0,0,1-3.74-1.37,5.22,5.22,0,0,1-1.34-3.81v-3c0-.67,0-1.07,0-1.18a2.44,2.44,0,0,0-.5-1.25,2.17,2.17,0,0,0-1.51-.74v-1.94a2.81,2.81,0,0,0,1.4-.51,1.66,1.66,0,0,0,.5-.74,4.29,4.29,0,0,0,.13-1.23v-4.81a2.68,2.68,0,0,0-.44-1.7,3.11,3.11,0,0,0-1.61-.89v-2h.48c1.34,0,2.48,0,3.42.14A7.69,7.69,0,0,1,659,263.5a5.54,5.54,0,0,1,2.38,4.81,5.69,5.69,0,0,1-1.6,4.18,5.52,5.52,0,0,1-1.56,1.13A20.41,20.41,0,0,1,655.44,274.6Z" transform="translate(-502 -221)"/>
                        <path d="M675.47,263.45a2.54,2.54,0,0,0-1.31.89,3,3,0,0,0-.38,1.65v16.2a4.28,4.28,0,0,0,.44,2.25,2,2,0,0,0,1.25.78v2a10.5,10.5,0,0,1-6.75-3q-3.53-3.53-3.53-9.81a16.54,16.54,0,0,1,.93-5.7,11.55,11.55,0,0,1,2.77-4.33,10.16,10.16,0,0,1,3-2.06,9.94,9.94,0,0,1,3.57-.8Zm1.49,0v-2a9.68,9.68,0,0,1,6.59,2.86q3.71,3.61,3.7,10t-3.54,9.81a10.31,10.31,0,0,1-6.75,3v-2a2.42,2.42,0,0,0,.73-.28,1.92,1.92,0,0,0,.48-.5,4.22,4.22,0,0,0,.49-2.25V266a3,3,0,0,0-.33-1.53,1.91,1.91,0,0,0-.52-.6A3.37,3.37,0,0,0,677,263.45Z" transform="translate(-502 -221)"/>
                        <path d="M692.14,268.34l1.93,3.19v9.72a4.53,4.53,0,0,0,.42,2.08,3.35,3.35,0,0,0,1.35,1.34l.55.31a.9.9,0,0,1,.47.82.84.84,0,0,1-.35.69,2.11,2.11,0,0,1-1.19.23h-4.19c-1.1,0-1.65-.31-1.65-.94a.77.77,0,0,1,.17-.52,3.65,3.65,0,0,1,.75-.51,3.64,3.64,0,0,0,1.74-3.5Zm7.6-6.36,9.64,16.75v8h-5l-12.3-21.21a6.22,6.22,0,0,0-.66-1,1.53,1.53,0,0,0-.65-.4l-.69-.25a.83.83,0,0,1-.43-.33.87.87,0,0,1-.17-.52,1,1,0,0,1,.17-.57.85.85,0,0,1,.43-.37,5.9,5.9,0,0,1,1.67-.13Zm9.64,13.85-1.92-3.25v-5a5,5,0,0,0-.44-2.33,3.61,3.61,0,0,0-1.57-1.38,1.37,1.37,0,0,1-.53-.39,1,1,0,0,1-.14-.54.8.8,0,0,1,.32-.71,1.79,1.79,0,0,1,1-.23h4.53a1.83,1.83,0,0,1,1.22.29.92.92,0,0,1,.3.69.81.81,0,0,1-.17.57,3.12,3.12,0,0,1-.82.52,3.11,3.11,0,0,0-1.45,1.5,4.87,4.87,0,0,0-.35,2Z" transform="translate(-502 -221)"/>
                        <path d="M723.85,261.49v2c-1.07.26-1.6,1.1-1.6,2.54v16.88a3.35,3.35,0,0,0,.27,1.5,1.86,1.86,0,0,0,1,.74v2a5.74,5.74,0,0,1-1.06-.09,9.88,9.88,0,0,1-1.28-.36,10.42,10.42,0,0,1-3.44-2,11.41,11.41,0,0,1-2.54-3.21,14.59,14.59,0,0,1-1.54-6.91A16.18,16.18,0,0,1,715,267.8a11,11,0,0,1,4-4.7,10.28,10.28,0,0,1,3.19-1.43A6.83,6.83,0,0,1,723.85,261.49Zm9.68,22.21a11.37,11.37,0,0,1-1.93,1.47,15.74,15.74,0,0,1-2.28,1.19,13.46,13.46,0,0,1-2.16.62,11.55,11.55,0,0,1-2.1.23v-2a2.41,2.41,0,0,0,.79-.3,1.47,1.47,0,0,0,.44-.51,2.91,2.91,0,0,0,.31-1.43v-5.3a1.51,1.51,0,0,0-.27-1,.58.58,0,0,0-.25-.23l-.84-.21a1,1,0,0,1-.87-1,.81.81,0,0,1,.41-.73,2.3,2.3,0,0,1,1.21-.25h7.23a2.06,2.06,0,0,1,1.15.23.88.88,0,0,1,.34.78,1.16,1.16,0,0,1-.14.58,1.78,1.78,0,0,1-.5.48,1.14,1.14,0,0,0-.45.47,2.33,2.33,0,0,0-.09.79Zm-8.2-20.23v-2a16,16,0,0,1,4.86,1.09,2.35,2.35,0,0,0,.87.25.69.69,0,0,0,.45-.14,6,6,0,0,0,.62-.77,1,1,0,0,1,.82-.45.81.81,0,0,1,.71.28,1.6,1.6,0,0,1,.21.93v7.06c0,.91-.34,1.37-1,1.37a1,1,0,0,1-.76-.27,3,3,0,0,1-.46-1.07,12,12,0,0,0-1-2.36,11.79,11.79,0,0,0-1.36-2.13,4.73,4.73,0,0,0-1.66-1.34A6.28,6.28,0,0,0,725.33,263.47Z" transform="translate(-502 -221)"/>
                      </g>
                      <rect className="cls-7" x="34.5" y="33.5" width="270" height="39" rx="12" fill="none" stroke="#000"/>
                    </g>
                  </g>
                </svg>
              </div>    
              <div 
                ref={targetRef => this.targetRefs[1] = targetRef} 
                className="target-box" 
                data-type="right"
              >
                <svg viewBox="0 -90 340 395.5">
                  <rect id="box-front" className="box-face" x="35" y="80.5" width="270" height="225"/>
                  <rect id="sticker" className="green-sticker" x="107" y="231" width="126" height="39"/>
                  <g id="Writing">
                      <rect className="writing" x="684" y="476" width="41" height="5" rx="2.5" transform="translate(907 736) rotate(180)"/>
                      <rect className="writing" x="662" y="476" width="16" height="5" rx="2.5" transform="translate(838 736) rotate(180)"/>
                      <rect className="writing" x="629" y="476" width="26" height="5" rx="2.5" transform="translate(782 736) rotate(180)"/>
                      <rect className="writing" x="618" y="476" width="5" height="5" rx="2.5" transform="translate(739 736) rotate(-180)"/>
                      <rect className="writing" x="218" y="242" width="5" height="5" rx="2.5"/>
                      <rect className="writing" x="186" y="242" width="26" height="5" rx="2.5"/>
                      <rect className="writing" x="163" y="242" width="16" height="5" rx="2.5"/>
                      <rect className="writing" x="116" y="242" width="41" height="5" rx="2.5"/>
                  </g>
                  <rect id="handle" className="box-handle" x="132" y="119" width="77" height="17" rx="6"/>
                  <g ref={lid => this.lids[1] = lid} id="lid">
                    <polygon className="lid-top" points="292.81 0 47.19 0 0 23 340 23 292.81 0"/>
                    <rect className="lid-front" y="23" width="340" height="58"/>
                    <g id="Label">
                    <g>
                      <path d="M626.38,262v21.3a1.45,1.45,0,0,0,.28,1,.62.62,0,0,0,.27.23c.08,0,.36.11.83.23a1,1,0,0,1,.87,1,.89.89,0,0,1-.47.77,2.3,2.3,0,0,1-1.14.21h-9.17a1.8,1.8,0,0,1-1-.24.8.8,0,0,1-.35-.7,1,1,0,0,1,.72-1l.7-.24a.52.52,0,0,0,.21-.21,1.61,1.61,0,0,0,.22-.93V265.28a1.63,1.63,0,0,0-.22-.93.55.55,0,0,0-.21-.2c-.07,0-.3-.12-.7-.25a1,1,0,0,1-.72-1,.8.8,0,0,1,.35-.7,1.8,1.8,0,0,1,1-.24h8.53ZM632,274.6a15,15,0,0,1,2.56.56,6.11,6.11,0,0,1,1.63.83,4,4,0,0,1,1.16,1.34,3.42,3.42,0,0,1,.41,1.65v3.75c0,.39.13.58.38.58a.36.36,0,0,0,.25-.08,2.48,2.48,0,0,0,.28-.31,1.23,1.23,0,0,1,.92-.62.87.87,0,0,1,.62.33.92.92,0,0,1,.31.65,2.65,2.65,0,0,1-.54,1.37,6,6,0,0,1-1.33,1.44A4.74,4.74,0,0,1,637,287a7.35,7.35,0,0,1-2,.26,4.94,4.94,0,0,1-3.73-1.37,5.18,5.18,0,0,1-1.35-3.81v-3c0-.67,0-1.07,0-1.18a2.45,2.45,0,0,0-.51-1.25,2.17,2.17,0,0,0-1.51-.74v-1.94a2.81,2.81,0,0,0,1.4-.51,1.66,1.66,0,0,0,.5-.74,4.29,4.29,0,0,0,.13-1.23v-4.81a2.68,2.68,0,0,0-.44-1.7,3.12,3.12,0,0,0-1.6-.89v-2h.47c1.34,0,2.48,0,3.42.14a7.65,7.65,0,0,1,3.75,1.38,5.54,5.54,0,0,1,2.38,4.81,5.69,5.69,0,0,1-1.6,4.18,5.52,5.52,0,0,1-1.56,1.13A20.41,20.41,0,0,1,632,274.6Z" transform="translate(-502 -221)"/>
                      <path d="M652.36,286.72H643.8a1.76,1.76,0,0,1-1-.24.81.81,0,0,1-.36-.7,1,1,0,0,1,.73-1l.7-.24a.68.68,0,0,0,.21-.21,1.62,1.62,0,0,0,.21-.93V265.28a1.64,1.64,0,0,0-.21-.93.75.75,0,0,0-.21-.2c-.07,0-.3-.12-.7-.25a1,1,0,0,1-.73-1,.81.81,0,0,1,.36-.7,1.76,1.76,0,0,1,1-.24h9a1.8,1.8,0,0,1,1,.24.82.82,0,0,1,.35.7,1,1,0,0,1-.73,1c-.39.13-.63.22-.69.25a.55.55,0,0,0-.21.2,1.56,1.56,0,0,0-.22.93v18.14a1.54,1.54,0,0,0,.22.93.52.52,0,0,0,.21.21l.69.24a1,1,0,0,1,.73,1,.82.82,0,0,1-.35.7,1.8,1.8,0,0,1-1,.24Z" transform="translate(-502 -221)"/>
                      <path d="M666.61,261.49v2c-1.07.26-1.6,1.1-1.6,2.54v16.88a3.35,3.35,0,0,0,.27,1.5,1.86,1.86,0,0,0,1,.74v2a5.74,5.74,0,0,1-1.06-.09,9.88,9.88,0,0,1-1.28-.36,10.32,10.32,0,0,1-3.44-2,11.24,11.24,0,0,1-2.54-3.21,14.59,14.59,0,0,1-1.54-6.91,16.18,16.18,0,0,1,1.38-6.85,11,11,0,0,1,4-4.7,10.28,10.28,0,0,1,3.19-1.43A6.83,6.83,0,0,1,666.61,261.49Zm9.68,22.21a11.37,11.37,0,0,1-1.93,1.47,15.74,15.74,0,0,1-2.28,1.19,13.46,13.46,0,0,1-2.16.62,11.55,11.55,0,0,1-2.1.23v-2a2.41,2.41,0,0,0,.79-.3,1.58,1.58,0,0,0,.44-.51,2.91,2.91,0,0,0,.31-1.43v-5.3a1.51,1.51,0,0,0-.27-1,.58.58,0,0,0-.25-.23l-.84-.21a1,1,0,0,1-.87-1,.81.81,0,0,1,.41-.73,2.3,2.3,0,0,1,1.21-.25H676a2.06,2.06,0,0,1,1.15.23.88.88,0,0,1,.33.78,1.16,1.16,0,0,1-.13.58,1.78,1.78,0,0,1-.5.48,1.14,1.14,0,0,0-.45.47,2.33,2.33,0,0,0-.09.79Zm-8.2-20.23v-2A16,16,0,0,1,673,262.6a2.35,2.35,0,0,0,.87.25.69.69,0,0,0,.45-.14,6,6,0,0,0,.62-.77,1,1,0,0,1,.82-.45.83.83,0,0,1,.71.28,1.6,1.6,0,0,1,.21.93v7.06c0,.91-.34,1.37-1,1.37a1,1,0,0,1-.76-.27,3,3,0,0,1-.46-1.07,12,12,0,0,0-1-2.36,11.79,11.79,0,0,0-1.36-2.13A4.73,4.73,0,0,0,670.4,264,6.28,6.28,0,0,0,668.09,263.47Z" transform="translate(-502 -221)"/>
                      <path d="M690.48,286.72h-8.56a1.76,1.76,0,0,1-1-.24.81.81,0,0,1-.36-.7,1,1,0,0,1,.73-1l.7-.24a.68.68,0,0,0,.21-.21,1.62,1.62,0,0,0,.21-.93V265.28a1.64,1.64,0,0,0-.21-.93.75.75,0,0,0-.21-.2c-.07,0-.3-.12-.7-.25a1,1,0,0,1-.73-1,.81.81,0,0,1,.36-.7,1.76,1.76,0,0,1,1-.24h9c.86,0,1.29.31,1.29.94a.92.92,0,0,1-.69,1l-.56.18c-.35.12-.53.52-.53,1.2v18.14c0,.68.18,1.08.53,1.2l.56.18a.93.93,0,0,1,.69,1c0,.63-.43.94-1.29.94ZM692,273.18h3v-7.9c0-.68-.18-1.08-.53-1.2l-.58-.18a.92.92,0,0,1-.69-1c0-.63.44-.94,1.31-.94h9c.91,0,1.36.31,1.36.94a1,1,0,0,1-.72,1c-.4.13-.63.22-.7.25a.55.55,0,0,0-.21.2,1.63,1.63,0,0,0-.22.93v18.07c0,.67.19,1.07.55,1.22l.58.2a1,1,0,0,1,.72,1c0,.64-.45,1-1.36,1h-9.06c-.85,0-1.27-.32-1.27-1a.94.94,0,0,1,.69-1l.58-.2c.35-.11.53-.52.53-1.22V275h-3Z" transform="translate(-502 -221)"/>
                      <path d="M707.46,262h4.77v2.19a3.7,3.7,0,0,0-1.32,1.25,7.74,7.74,0,0,0-1,2.92l-.18,1.11a1.47,1.47,0,0,1-.39.82,1,1,0,0,1-.76.3,1,1,0,0,1-.77-.32,1.23,1.23,0,0,1-.3-.86Zm14.29,0v21.3a1.51,1.51,0,0,0,.27,1,.75.75,0,0,0,.27.23c.09,0,.36.11.83.23a1,1,0,0,1,.88,1,.88.88,0,0,1-.48.77,2.34,2.34,0,0,1-1.16.21h-9.28a2.4,2.4,0,0,1-1.2-.24.82.82,0,0,1-.41-.74,1,1,0,0,1,.87-1c.47-.12.75-.2.83-.23a.6.6,0,0,0,.26-.23,1.51,1.51,0,0,0,.27-1V262Zm6.27,0v7.41a1.23,1.23,0,0,1-.3.86,1.09,1.09,0,0,1-1.52,0,1.47,1.47,0,0,1-.39-.82l-.18-1.11a7.92,7.92,0,0,0-1-2.92,4,4,0,0,0-1.33-1.25V262Z" transform="translate(-502 -221)"/>
                    </g>
                    <rect className="cls-7" x="34.5" y="33.5" width="270" height="39" rx="12" fill="none" stroke="#000"/>
                  </g>
                  </g>
                </svg>
              </div>
            </div>
            <div className="drag-box-container">
              {actStarted && data.statements[statementIndex] && (this.textIn(data.statements[statementIndex].text))}
            </div>
            <div className="button-container">
              <Button 
                text="Next"
                icon="next"
                onClick={() => {
                  this.props.history.push(context.goNextPage(context.state.currentModule));
                  if(context.state[`module${context.state.currentModule}status`] !== 2){
                    if(!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress();
                  }
                }}  
              />
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}

export default Mod4Page4;