import React, { Component, Fragment } from 'react'
import Button from '../../shared/Button';
import Loader from '../../shared/Loader';

import { Consumer, Context } from '../../../context';
import PageProgBar from '../../shared/PageProgBar';
import Alert from '../../shared/Alert';

class Mod2IdeaEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: [
        { "name": "cansay", "content": "Things you can say:" },
        { "name": "coulddo", "content": "Things you could do:" },
        { "name": "touse", "content": "Important words to use:" },
        { "name": "toavoid", "content": "Important words to avoid:" }
      ],
      currentIndex: 1,
      section1show: true,
      showNext: false,
      pageUnlocked: false,
      showAlert: false,
      isLoading: true
    }
  }

  componentDidMount() {
    setTimeout(() => {

      this.retrieveInfoFromStorage();
    }, 3000)
  }

  retrieveInfoFromStorage = async () => { // GET request and setState
    // let cansay = localStorage.getItem('cansay')
    // let coulddo = localStorage.getItem('coulddo')
    // let touse = localStorage.getItem('touse')
    // let toavoid = localStorage.getItem('toavoid')
    let cansay = await this.context.getItem('cansay')
    let coulddo = await this.context.getItem('coulddo')
    let touse = await this.context.getItem('touse')
    let toavoid = await this.context.getItem('toavoid')
    this.setState({
      cansay: cansay.value,
      coulddo: coulddo.value,
      touse: touse.value,
      toavoid: toavoid.value,
      isLoading: false
    }, () => {
    })
  }

  historyPusher = (path) => {
    this.props.history.push(path);
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
    })
  }

  createInputs = () => {
    const inputs = this.state.inputs.map((input, i) => {
      return (
        <div key={i} className="input-section">
          <label className="bold">{input.content}</label>
          <input
            type="text"
            name={input.name}
            onChange={this.onInputChange}
            value={this.state[input.name] ? this.state[input.name] : ''}
            placeholder={this.state[input.name] && input.name}
          />
        </div>
      )
    })
    return inputs;
  }

  postToLocalStorage = () => {
    const { cansay, coulddo, touse, toavoid } = this.state
    // localStorage.setItem('cansay', cansay);
    // localStorage.setItem('coulddo', coulddo);
    // localStorage.setItem('touse', touse);
    // localStorage.setItem('toavoid', toavoid);
    this.context.setItem('cansay', cansay);
    this.context.setItem('coulddo', coulddo);
    this.context.setItem('touse', touse);
    this.context.setItem('toavoid', toavoid);
    this.setState({ showAlert: !this.state.showAlert })
  }

  updateNextButton = () => {
    this.setState({ showNext: !this.state.showNext })
  }

  alertClose = () => {
    this.setState({ showAlert: !this.state.showAlert })
  }
  renderLoader = () => {
    if (this.state.isLoading) {
      return (
        <Loader in={this.state.isLoading} />
      )
    } else {
      return (
        <Consumer>
          {(context) => (
            <div>
              <PageProgBar pageTitle="How Can You Be Supportive?" />
              <Alert
                showAlert={this.state.showAlert}
                text="Your ideas have been saved."
                subText="You can find them in the Supporting Changes in Drinking: 'Bookmarks' page."
                buttonText="OK"
                onClose={() => {
                  this.alertClose()
                  this.props.history.push('/mod-2-results');
                }}
              />
              <div className="inner-page-content">
                <div className="col-section">
                  <p className="instruct-txt">Did you check with your family member about what they would find supportive? Edit your ideas below and they will be saved.</p>
                  <br />
                  <div className="support-form-container">
                    <form className="support-form">
                      {this.createInputs()}
                    </form>
                    <div className="submit-container">
                      <Button
                        text="Submit"
                        icon="check"
                        onClick={() => {
                          this.updateNextButton();
                          this.postToLocalStorage();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Consumer>
      )
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderLoader()}
      </Fragment>
    )
  }
}

Mod2IdeaEdit.contextType = Context;
export default Mod2IdeaEdit;