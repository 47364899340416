import React, { Component } from 'react'
import Button  from '../../shared/Button';

class Mod1Results extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }
  
  render() {
    return (
      <div>
        <div className="reminder-prompt">
          <h1>Your Bookmarks:<br/>Life With an Interlock</h1>   
        </div>
        <div className="button-container">
          <Button 
            text="Home"
            icon="next"
            onClick={() => this.props.history.push('/home')}
          />
        </div>
      </div>
    )
  }
}

export default Mod1Results;