import React, { Component, Fragment } from 'react'
import Button from '../../shared/Button';
import { Consumer, Context } from '../../../context';
import PageProgBar from '../../shared/PageProgBar';
import Alert from '../../shared/Alert';
import data from '../../../assets/json/core_content.json';
import Triangle from '../../shared/DropdownTriangle/Triangle';
import BackendService from '../../../utils/BackendService';
class Mod2Page4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      showAlert: false,
      showBMarkAlert: false,
      bookmarkedTopics: []
    }
  }

  toggleAccordion = (e) => {
    this.setState(
      {
        [`section-1-show`]: false,
        [`section-2-show`]: false,
        [`section-3-show`]: false,
        [`section-4-show`]: false,
        [`section-5-show`]: false,
        [`section-6-show`]: false,
        [`section-${e.target.dataset.section}-show`]: this.state[`section-${e.target.dataset.section}-show`] ? !this.state[`section-${e.target.dataset.section}-show`] : true
      }
    )
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleUpdateSubmit = (target) => {
    this.setState({ showAlert: true })
    // localStorage.setItem(`${target}`, this.state[target]);
    this.context.setItem(`${target}`, this.state[target])
  }

  handleBookmarkSubmit = (target) => {
    this.setState({
      showBMarkAlert: true,
      bookmarkedTopics: this.state.bookmarkedTopics.includes(target) ? this.state.bookmarkedTopics : [...this.state.bookmarkedTopics, target]
    }, () => {
      // localStorage.setItem('bookmarkedTopics', JSON.stringify(this.state.bookmarkedTopics));
      //POST Bookmarks
      this.context.setItem('bookmarkedTopics', JSON.stringify(this.state.bookmarkedTopics))
    })
  }

  alertClose = () => {
    this.setState({ showAlert: false })
  }

  buildPage = () => {
    const { data } = this.state;
    return (
      <Fragment>
        <div className="inner-content-alt">
          <p>{data.m4.intro}</p>
          <p>{data.m4.summary_wrap}</p>
        </div>
        {data.m4.dropdowns.map((section, i) => {
          return (
            <div key={i} className={`dropdown-section-alt ${this.state[`section-${i + 1}-show`] === true ? 'panel-show' : null}`}>
              <h3 key={i} data-section={i + 1} onClick={this.toggleAccordion}>{section.header}<span className="dropdown-header "><div className="expand-icon"><Triangle data={i + 1} id="icon" /></div></span></h3>
              <div className="inner-section-alt">
                <div className="idea-edit" data-edit-src={i + 1}>
                  <h4>Your Ideas:</h4>
                  <p>Click in the text box below to share your ideas and then click Save Ideas.</p>
                  <input
                    type="text"
                    name={section.key}
                    value={this.state[section.key] ? this.state[section.key] : ''}
                    onChange={this.onInputChange}
                  />
                  <div className="button-container-alt">
                    <div className="button-row">
                      <Button
                        text="Save Ideas"
                        icon="edit"
                        onClick={() => {
                          this.handleUpdateSubmit(section.key)
                          this.handleBookmarkSubmit(section.key)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p>{section.content_intro}</p>
                <ul>
                  {section.points.map((point, i) => <li key={i}>{point}</li>)}
                </ul>
              </div>
            </div>
          )
        })}
        <Alert
          showAlert={this.state.showBMarkAlert}
          text="This topic has been saved to Supporting Changing in Drinking 'Bookmarks' page."
          buttonText="OK"
          onClose={() => this.setState({ showBMarkAlert: false })}
        />
      </Fragment>
    )
  }

  render() {
    return (
      <Consumer>
        {(context) => (
          <div ref={content => this.content = content}>
            <PageProgBar pageTitle="Putting It All Together" />
            {this.buildPage()}
            <div className="button-container">
              <Button
                text="Next"
                icon="next"
                onClick={() => {
                  context.goNextPage(context.state.currentModule) === undefined
                    ? this.props.history.push('/mod-2-conclude')
                    : context.goNextPage(context.state.currentModule);
                  if (context.state[`module${context.state.currentModule}status`] !== 2) {
                    if (!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress(context.state.currentModule, 2, 3) // (current mod), (status to be applied), (target for unlock)
                  }
                }}
              />
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}


Mod2Page4.contextType = Context;

export default Mod2Page4;