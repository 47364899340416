import React, { Component } from 'react'
import { TweenMax, Power3, TimelineLite, Back } from 'gsap';
import { SplitText } from '../../../vendor/gsap/SplitText';
import { TweenLite, Elastic } from 'gsap';

let x, swipeAmount = 150, sx, sy, x2;

class SwipeCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "You shouldn't drink"
    }
    this.txtContainer = null
    this.sWords = null
  }
  //
  componentDidMount() {
    TweenLite.delayedCall(3, () => {
      this.fireAnim();
    })
    this.props.loadShooter(this.txtContainer, this.feedback);
  }
  //
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.text !== prevState.text) {
      return { newText: nextProps.text }
    }
    else return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.text !== this.props.text) {
      this.setState({ newText: this.props.text, newImg: this.props.newImg }, () => {
        if (!this.props.menuStatus && this.props.activityStarted) {
          TweenLite.delayedCall(1.5, () => {
            this.fireAnim();
          })
        } else return;
      })
    }
  }
  // componentWillReceiveProps(nextProps){
  //   console.log(nextProps)
  //   this.setState({ text: nextProps.text })
  //   if(nextProps.showAlert){
  //     return;
  //   } else {
  //     if(!this.props.menuStatus && this.props.activityStarted){
  //       TweenLite.delayedCall(.5, () => {
  //         this.fireAnim();
  //       })
  //     } else {
  //       return;
  //     }
  //   }
  // }
  //
  fireAnim = () => {
    let split;
    document.querySelector('.swipe-holder').addEventListener('mousedown', this.onTouchStart);
    document.querySelector('.swipe-holder').addEventListener('touchstart', this.onTouchStart);
    document.querySelector('.swipe-holder').addEventListener('mouseup', this.onTouchEnd);
    document.querySelector('.swipe-holder').addEventListener('touchend', this.onTouchEnd);
    document.addEventListener('mouseup', this.onTouchEnd);
    document.addEventListener('mousedown', this.onTouchStart);
    let tl = new TimelineLite();
    split = new SplitText(this.sWords, { type: "words", position: "relative" });
    tl.clear()
      .set(this.txtContainer, { autoAlpha: 1, x: '0%', y: 800, rotation: 0 })
      .to(this.txtContainer, 0.15, { autoAlpha: 1 }, 0)
      .to(this.txtContainer, 0.8, { y: 10, ease: Power3.easeIn }, 0)
      .add(TweenMax.staggerFrom(split.words, 0.3, { y: 60, autoAlpha: 0, ease: Back.easeOut, onStartScope: this.sWords }, .05), .8)
      .play();
  }
  //
  onMove = (e) => {
    e.preventDefault();
    x -= x2 - (e.clientX || e.changedTouches[0].clientX);
    x2 = e.clientX || e.changedTouches[0].clientX;
    TweenLite.set(this.txtContainer, { x: x * 0.2 + '%', y: x * 0.03, rotation: x * 0.01 });
    if (e.clientX < 100) {
      this.onTouchEnd(e);
    }
    return false;
  };
  //
  onTouchStart = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', this.onMove);
    document.addEventListener('touchmove', this.onMove);
    x = 0;
    x2 = sx = e.clientX || e.changedTouches[0].clientX;
    sy = e.clientY || e.changedTouches[0].clientY;
  };
  //
  onTouchEnd = (e) => {
    e.preventDefault();
    document.removeEventListener('mousemove', this.onMove);
    document.removeEventListener('touchmove', this.onMove);
    //swipe left
    if ((e.clientX || e.changedTouches[0].clientX) < sx && sx - (e.clientX || e.changedTouches[0].clientX) > swipeAmount && Math.abs(sy - (e.clientY || e.changedTouches[0].clientY)) < 120) {
      this.feedback(false);
      TweenLite.to(this.txtContainer, 0.8, { x: '-500%', y: 50, rotation: -18, ease: Power3.easeOut, onComplete: this.txtContainer.empty, onCompleteScope: this.txtContainer });
      //swipe right
    } else if ((e.clientX || e.changedTouches[0].clientX) > sx && (e.clientX || e.changedTouches[0].clientX) - sx > swipeAmount && Math.abs(sy - (e.clientY || e.changedTouches[0].clientY)) < 120) {
      this.feedback(true);
      TweenLite.to(this.txtContainer, 0.8, { x: '500%', y: 50, rotation: 18, ease: Power3.easeOut, onComplete: this.txtContainer.empty, onCompleteScope: this.txtContainer });
    } else {
      TweenLite.to(this.txtContainer, 0.6, { x: '0%', y: 0, ease: Elastic.easeOut, rotation: 0 });
    }
  }
  //
  feedback = (bool) => {
    this.setState({ newText: 'image not loaded', newImg: <img alt="not  loaded" /> }, () => {
    })
    console.log("feedback")
    document.querySelector('.swipe-holder').removeEventListener('mousedown', this.onTouchStart);
    document.querySelector('.swipe-holder').removeEventListener('touchstart', this.onTouchStart);
    document.querySelector('.swipe-holder').removeEventListener('mouseup', this.onTouchEnd);
    document.querySelector('.swipe-holder').removeEventListener('touchend', this.onTouchEnd);
    document.removeEventListener('mouseup', this.onTouchEnd);
    document.removeEventListener('mousedown', this.onTouchStart);
    TweenLite.delayedCall(1, () => {
      this.props.prepAlert(bool);
    })
  }
  //
  render() {
    return (
      <div
        ref={txtContainer => this.txtContainer = txtContainer}
        className="swipe-holder">
        <div className="swipe-img-container">
          <img alt={this.state.newText} draggable={false} src={require(`../../../assets/images/swiper2/${this.props.newImg}`)} />
        </div>
        <p ref={sWords => this.sWords = sWords} className="act-txt">{this.state.newText ? this.state.newText : this.state.text}</p>
      </div>
    )
  }
}
export default SwipeCard;