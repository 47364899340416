import React, { Component } from 'react'
import Button from '../../shared/Button';
import { Consumer, Context } from '../../../context';
import scenes from '../../../assets/json/comm_scenarios_act.json';
import BackendService from '../../../utils/BackendService';
class Mod4Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      scenarios: scenes.scenarios
    }
  }
  asyncFunction = async () => {
    if (this.context.state.userToken) {
      let holder = await this.context.getItem('scenarioData')
      if (holder.value) {
        let data = JSON.parse(holder.value)
        this.setState({ data }, () => {
          this.setState({
            jsonLoaded: true
          })
        });

      }
    }
    else {
      this.middleWare()
    }
  }
  middleWare = () => {
    setTimeout(() => {
      this.asyncFunction()
    }, 500)
  }

  componentDidMount() {
    this.asyncFunction()
  }

  generateReview = () => {
    if (this.state.jsonLoaded) {
      const reviewItems = this.state.data.map((item, i) => {
        return (
          <div key={i} className="comm-review">
            <div className="results-scenario-title"><h1>{`Scenario ${item.activeScene}`}: </h1><h3>{this.state.scenarios[i].title}</h3></div>
            <h4>Your Selections:</h4>
            <ul>
              {item.prompts && item.prompts.map((prompt) => { return <li key={prompt}>{prompt}</li> })}
              {item.other !== '' && <li>{item.other}</li>}
            </ul>
          </div>

        )
      })
      return reviewItems;
    }
  }


  render() {
    return (
      <div>
        <div className="reminder-prompt">
          <h1>Your Bookmarks:<br />Effective Communication</h1>
          <div className="btn-holder">
            <Button
              text="Add Reminders"
              icon="add"
              onClick={() => this.props.history.push('/mod-3-remind')}
            />
          </div>
        </div>
        <div className="scenario-review">
          {this.generateReview()}
        </div>
        <div className="button-container">
          <Button
            text="Home"
            icon="next"
            onClick={() => this.props.history.push('/congratulations')}
          />
        </div>
      </div>
    )
  }
}
Mod4Results.contextType = Context;
export default Mod4Results;