import React, { Component } from 'react';
import { Provider } from './context';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './style_sheets/main.scss';
import Header from './components/shared/Header';
import PageContent from './components/shared/PageContent';
import ScrollToTop from './components/utils/ScrollToTop';
import ImageLoader from './components/shared/SwipeCard/imageLoader';
import SwipeData from './assets/json/m2_swiper.json';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      //Provider for Context
      <Provider>
        <Router>
          <ScrollToTop>
            <div className="wrapper">
              <Route path='/' component={Header} />
              <Route path='/' component={PageContent} />
            </div>
          </ScrollToTop>
        </Router>
        {/* <LanguageWidget/>  */}
      </Provider>
    );
  }
}

export default App;

