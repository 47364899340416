import React, { Component } from 'react'
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Consumer } from '../../../context';
import Home from '../../course/Home';
import About from '../../course/About';
import Outro from '../../course/Outro';
import Login from '../../course/Login';
import Life from '../../course/Life';
import Resources from '../../course/Resources';
import Profile from '../../course/Profile';
import Mod1Page1 from '../../course/module1/mod-1-1';
import Mod1Page2 from '../../course/module1/mod-1-2';
import Mod1Page3 from '../../course/module1/mod-1-3';
import Mod1Conclude from '../../course/module1/mod-1-conclude';
import Mod1Results from '../../course/module1/mod-1-results';
import Mod1Remind from '../../course/module1/mod-1-remind';
import Mod2Page1 from '../../course/module2/mod-2-1';
import Mod2Page2 from '../../course/module2/mod-2-2';
import Mod2Page3 from '../../course/module2/mod-2-3';
import Mod2Page4 from '../../course/module2/mod-2-4';
import Mod2IdeaEdit from '../../course/module2/mod-2-idea-edit';
import Mod2Conclude from '../../course/module2/mod-2-conclude';
import Mod2Results from '../../course/module2/mod-2-results';
import Mod2Remind from '../../course/module2/mod-2-remind';
import Mod3Page1 from '../../course/module3/mod-3-1';
import Mod3Page2 from '../../course/module3/mod-3-2';
import Mod3Page3 from '../../course/module3/mod-3-3';
import Mod3Page4 from '../../course/module3/mod-3-4';
import Mod3Conclude from '../../course/module3/mod-3-conclude';
import Mod3Results from '../../course/module3/mod-3-results';
import Mod3Remind from '../../course/module3/mod-3-remind';
import Mod4Page1 from '../../course/module4/mod-4-1';
import Mod4Page2 from '../../course/module4/mod-4-2';
import Mod4Page3 from '../../course/module4/mod-4-3';
import Mod4Page4 from '../../course/module4/mod-4-4';
import Mod4Page5 from '../../course/module4/mod-4-5';
import Mod4Conclude from '../../course/module4/mod-4-conclude';
import Mod4Results from '../../course/module4/mod-4-results';
import Mod4Remind from '../../course/module4/mod-4-remind';
import NotFound from '../../utils/NotFound';
import LoginService from '../../../utils/LoginService';
import PrivateRoute from './PrivateRoute';
import DataPreload from '../DataPreload';


class PageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false
    }
    this.content = null
  }

  componentDidMount() {
    window.scrollTo(0, 0);

  }





  render() {
    return (
      <Consumer>
        {(context) => (
          
          <div className="page-content" ref={content => this.content = content}>
             {/* <button onClick = {()=> context.updateUser()}>
          Clear the DB
        </button> */}
            <TransitionGroup className="transition-group">
              <CSSTransition
                key={this.props.location.key}
                timeout={{ enter: 1400, exit: 300 }}
                classNames={'fade'}
              >
               
                <section className="route-section">
                  <Switch location={this.props.location}>
                    <Route exact path='/' component={Login} />
                    <Route path='/life' component={Life} />
                    <Route path='/about' component={About} />
                    <Route path='/congratulations' component={Outro} />
                    <Route path='/home' component={Home} />
                    <Route path='/profile' component={Profile} />
                    <Route path='/resources' component={Resources} />
                    <Route path='/mod-1-1' component={Mod1Page1} />
                    <Route path='/mod-1-2' component={Mod1Page2} />
                    <Route path='/mod-1-3' component={Mod1Page3} />
                    <Route path='/mod-1-conclude' component={Mod1Conclude} />
                    <Route path='/mod-1-results' component={Mod1Results} />
                    <Route path='/mod-1-remind' component={Mod1Remind} />
                    <Route path='/mod-2-1' component={Mod2Page1} />
                    <Route path='/mod-2-2' component={Mod2Page2} />
                    <Route path='/mod-2-3' component={Mod2Page3} />
                    <Route path='/mod-2-4' component={Mod2Page4} />
                    <Route path='/mod-2-idea-edit' component={Mod2IdeaEdit} />
                    <Route path='/mod-2-conclude' component={Mod2Conclude} />
                    <Route path='/mod-2-results' component={Mod2Results} />
                    <Route path='/mod-2-remind' component={Mod2Remind} />
                    <Route path='/mod-3-1' component={Mod3Page1} />
                    <Route path='/mod-3-2' component={Mod3Page2} />
                    <Route path='/mod-3-3' component={Mod3Page3} />
                    <Route path='/mod-3-4' component={Mod3Page4} />
                    <Route path='/mod-3-conclude' component={Mod3Conclude} />
                    <Route path='/mod-3-results' component={Mod3Results} />
                    <Route path='/mod-3-remind' component={Mod3Remind} />
                    <Route path='/mod-4-1' component={Mod4Page1} />
                    <Route path='/mod-4-2' component={Mod4Page2} />
                    <Route path='/mod-4-3' component={Mod4Page3} />
                    <Route path='/mod-4-4' component={Mod4Page4} />
                    <Route path='/mod-4-5' component={Mod4Page5} />
                    <Route path='/mod-4-conclude' component={Mod4Conclude} />
                    <Route path='/mod-4-results' component={Mod4Results} />
                    <Route path='/mod-4-remind' component={Mod4Remind} />
                    <Route component={NotFound} />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
            { this.props.children}
            <DataPreload/>
          </div>
        )}
      </Consumer>
    )
  }
}

export default withRouter(PageContent);