import React, { Component, Fragment } from 'react'
import styles from './CircleCheckAnimation.module.scss';
import { TweenMax, TimelineMax } from 'gsap';


class CircleCheckAnimation extends Component {
  constructor(props){
    super(props);
    this.state = {}
    this.circle = null;
    this.check = null;
  }

  componentDidMount(){
    TweenMax.set([this.check, this.circle], { drawSVG: '0%' });
    setTimeout(() => {
      this.fireAnimation()
    }, 750)
  }

  fireAnimation = () => {
    let tl = new TimelineMax();
    tl.to(this.circle, .5, { drawSVG: '100%' })
      .to(this.check, .4, { drawSVG: '100%' }, '-=.1');
  }

  render() {
    return (
      <Fragment>
        <svg viewBox="0 0 254 254">
            <clipPath id="clip-path" transform="translate(-703 -186)">
              <polygon className={styles["cls-1"]} points="810.83 318.42 783.26 288.55 752.86 318.95 752.86 318.95 810.21 376.3 907 279.42 884.93 256.91 810.83 318.42"/>
            </clipPath>
          <path ref={el => this.circle = el} className={styles["cls-2"]} d="M710.23,305.46c-.1,1.69-.17,3.38-.21,5.09,0,.81,0,1.63,0,2.45a119.88,119.88,0,1,0,.23-7.54Z" transform="translate(-703 -186)"/>
          <g id="Check_SVG" data-name="Check SVG">
            <g className={styles["cls-3"]}>
              <polyline ref={el => this.check = el} className={styles["cls-4"]} points="62 115 107 161 196 78"/>
            </g>
          </g>
        </svg>
      </Fragment>
    )
  }
}
export default CircleCheckAnimation;

