import React, { Component } from 'react'
import styles from './LanguageWidget.module.scss';
import { ReactComponent as LanguageIcon } from '../../../assets/svgs/translation.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svgs/close.svg';
import { Consumer, Context } from '../../../context';
import { TweenLite } from 'gsap/all';


class LanguageWidget extends Component {
  constructor(props) {
    super(props);
    this.icon = null;
    this.list = null;
  }

  componentDidMount() {
    TweenLite.set(this.list, { className: '+=hidden' })
    let url = window.location.href;

    url.includes('bsmartapp') ?
      this.switchLanguage('en') :
      this.switchLanguage('es-MX')

  }

  switchLanguage = (lang) => {
    const _Localize = window.Localize;
    _Localize.setLanguage(lang)
  }

  createLanguageList = () => {
    let languages = this.context.state.languageData.languages;
    const languageList = languages.map((lang, i) => {
      return (
        <li
          key={i}
          onClick={() => {
            this.switchLanguage(lang.code)
          }}
          className={styles.languageBtn}
        >
          {lang.title}
        </li>
      )
    });
    return languageList;
  }

  animate = () => {
    if (this.context.state.isLangMenuOpen) {
      TweenLite.set(this.icon, {
        autoAlpha: 0, className: '-=hidden', onComplete: () => {
          TweenLite.to(this.icon, .5, { autoAlpha: 1, delay: .3 })
        }
      })
      TweenLite.to(this.list, .1, {
        autoAlpha: 0, onComplete: () => {
          TweenLite.set(this.list, { className: '+=hidden' })
        }
      })
    } else {
      TweenLite.to(this.icon, .1, {
        autoAlpha: 0, onComplete: () => {
          TweenLite.set(this.icon, { className: '+=hidden' })
        }
      })
      TweenLite.set(this.list, {
        autoAlpha: 0, className: '-=hidden', onComplete: () => {
          TweenLite.to(this.list, .5, { autoAlpha: 1, delay: .3 })
        }
      })
    }
  }

  render() {
    const { container, expanded, iconWrapper, languageList, closeIconWrap } = styles;
    return (
      <Consumer>
        {(context) => (
          <div
            className={context.state.isLangMenuOpen ? `${container} ${expanded}` : `${container}`}
            onClick={() => {
              if (!context.state.isLangMenuOpen) {
                context.openLanguageMenu();
                this.animate();
              }
            }}
          >
            <div ref={icon => this.icon = icon} className={iconWrapper}>
              <LanguageIcon />
            </div>
            <div ref={list => this.list = list} className={languageList}>
              <div
                className={closeIconWrap}
                onClick={() => {
                  context.closeLanguageMenu();
                  this.animate();
                }}>
                <CloseIcon />
              </div>
              <ul>
                {this.createLanguageList()}
              </ul>
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}

LanguageWidget.contextType = Context;
export default LanguageWidget;