import React, { Component } from 'react'
import data from '../../../assets/json/hotspot_act.json';
import Alert from '../../shared/Alert';
import Button from '../../shared/Button';
import PageProgBar from '../../shared/PageProgBar';
import { TweenLite, TimelineLite, TweenMax } from 'gsap';
import { Consumer } from '../../../context';


class Mod1Page2 extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: data,
      currentIndex: 0,
      showInstruct: false
    }
    this.hotspots = []
    this.images = []
  }

  componentDidMount(){
    TweenLite.set([this.images[1], this.images[2]], { x: 2000 })
    setTimeout(() => {
      this.setState({ showInstruct: true });
    }, 1500)
  }

  createHotspots = (kind) => {
    const hotspots = this.state.data.hotspots.map((spot, i) => {
      if(spot.type === kind){
        return(
          <div 
            ref={hotspots => this.hotspots[i] = hotspots}
            key={i + 1} 
            className={`hotspot ${spot.class} not-revealed`} 
            data-hotspot={`${spot.class}`}
            onClick={(e) => {
              this.revealHotspot(e)
              TweenMax.to(e.target, 1, { className: '-=not-revealed' })
              TweenMax.to(e.target, 1, { className: '+=revealed' })
            }}
          ></div>
        )
      } else return null;
    })
    return hotspots;
  }

  revealHotspot = (e) => {
    this.state.data.hotspots.forEach((spot) => {
      if(spot.class === e.target.dataset.hotspot){
        this.setState({
          showAlert: true,
          alertTitle: spot.name,
          currentHotspotText: spot.desc
        })
      } else { 
        return null;
      }
    })
  }

  alertClose = () => {
    this.setState({ showAlert: false, showInstruct: false })
  }

  advanceSlide = () => {
    let tl = new TimelineLite();
    TweenLite.to(this.hotspots, .1, { autoAlpha: 0 });
    tl.to(this.images[this.state.currentIndex], 1.3, { x: -3000 }, 0)
      .to(this.images[this.state.currentIndex + 1], 1, { x: 0 }, 0)
    this.setState({ currentIndex: this.state.currentIndex + 1 }, () => {
      setTimeout(() => {
        this.setState({ currentImage: this.images[this.state.currentIndex + 1] })
        TweenLite.to(this.hotspots, .3, { autoAlpha: 1, delay: .5 });
      }, 500)
    })  
  }

  previousSlide = () => {
    let tl = new TimelineLite();
    TweenLite.to(this.hotspots, .1, { autoAlpha: 0 });
    tl.to(this.images[this.state.currentIndex], 1.3, { x: 3000 }, 0)
      .to(this.images[this.state.currentIndex - 1], 1, { x: 0 }, 0)
    this.setState({ currentIndex: this.state.currentIndex - 1 }, () => {
      setTimeout(() => {
        this.setState({ currentImage: this.images[this.state.currentIndex - 1] })
        TweenLite.to(this.hotspots, .3, { autoAlpha: 1, delay: .5 });
      }, 500)
    })  
  }

  historyPusher = (path) => {
    this.props.history.push(path);
  }

  render() {
    return (
      <Consumer>
        {(context) => (
          <div>
            <PageProgBar pageTitle="Hotspot Activity"/>
            <Alert 
              showAlert={this.state.showInstruct} 
              text="Explore some of the common challenges."
              subText={this.state.data.instruct}
              buttonText="OK"
              onClose={() => this.alertClose()}
              allowDanger={true}
            />
            <Alert 
              showAlert={this.state.showAlert} 
              subText={this.state.currentHotspotText}
              buttonText="OK"
              allowDanger={true}
              expand={true}
              onClose={() => this.alertClose()}
            />
            <div className="slideshow-container">
              <div className="main-image">
                <div ref={carImg => this.images[0] = carImg} className="snugger">
                  <img className="car-img" alt="Inside of Car" src={require('../../../assets/images/hotspot_act/inside-car-color.jpg')} />
                  {this.createHotspots('car_image')}
                </div>
                <div ref={fightImg => this.images[1] = fightImg} className="snugger">
                  <img className="fight-img" alt="Couple having an argument" src={require('../../../assets/images/hotspot_act/couch_convo.jpg')} />
                  {this.createHotspots('fight_image')}
                </div>
                <div ref={momImg => this.images[2] = momImg} className="snugger">
                  <img className="mom-img" alt="Mom working from home" src={require('../../../assets/images/hotspot_act/mom_alone.jpg')} />
                  {this.createHotspots('mom_image')}
                </div>
              </div>
              <div className="button-box-split">
              <Button 
                disabled={this.state.currentIndex === 0 ? true : null}
                onClick={this.previousSlide}
                altColor={true}  
                icon="back"
              />
              <Button 
                disabled={this.state.currentIndex === 2 ? true : null}
                onClick={this.advanceSlide}
                altColor={true}  
                icon="next"
              />
            </div>
            </div>
            
            <div className="button-container">
              <Button 
                disabled={this.state.currentIndex === 2 ? false : true}
                text="Next"
                icon="next"
                onClick={() => {
                  this.props.history.push(context.goNextPage(context.state.currentModule));
                  if(context.state[`module${context.state.currentModule}status`] !== 2){
                    if(!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress();
                  }
                }}
              />
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}
export default Mod1Page2;