import React, { Component } from 'react'
import Button from '../../shared/Button';

class Mod1Conclude extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className="inner-page-content conclusion">
        <h1 className="conclude-header">What Did You Learn?</h1>
        <p>We hope you now have a better understanding of the Interlock, and how to cope with it in your family’s life.  Certainly, it takes the whole family’s effort to get through a difficult time together, and understanding the Interlock better should help.  The other modules in B-SMART will give you additional skills and information to cope with this challenging time.</p>
        <div className="button-box">
          <Button
            text="Next" 
            icon="next"
            onClick={() => {
              this.props.history.push('/home');
            }}
          />
        </div>
      </div>
    )
  }
}
export default Mod1Conclude;