import React, { Component } from 'react';
import styles from './Alert.module.scss';
import { Transition } from 'react-transition-group';
import { TweenMax, Back } from 'gsap';
import Button from '../Button';

class Alert extends Component {
  constructor(props){
    super(props);
    this.state = {
      alert: false,
      dim: false
    }
    this.outer = null;
  }

  componentWillReceiveProps(nextProps){
    this.setState({ alert: nextProps.showAlert })
  }


  render(){
    const { alert } = this.state;
    return(
      <Transition
        timeout={1000}
        mountOnEnter
        unmountOnExit
        in={alert}
        addEndListener={(node, done) => {
          TweenMax.to(this.outer, .3, { 
            css: { 
              backgroundColor: alert 
              ? 'rgba(0, 0, 0, .2)' 
              : 'rgba(0, 0, 0, 0)' 
            }, 
            delay: alert 
            ? .2 
            : .1 
          })
          TweenMax.to(node, 0.5, { 
            scaleX: alert ? 1 : .8,
            scaleY: alert ? 1 : .8,
            autoAlpha: alert ? 1 : 0, 
            ease: alert ? Back.easeOut : Back.easeIn,
            onComplete: () => {
              done();
            } 
          });
        }}
      >
        <div ref={outer => this.outer = outer} className={this.state.dim ? styles.alertWrapper : `${styles.alertWrapper}` }>
          <div className={`${this.props.expand ? styles.wideAlert : styles.container} ${styles.transitionCard} ${styles.simple} ${styles[`${this.props.alertStyle}`]}`}>
            <h1>{this.props.text}</h1>
            {
              this.props.allowDanger 
              ? <p className={this.props.subBold === true ? styles.bold : null} dangerouslySetInnerHTML={{ __html: this.props.subText }}></p> 
              : <p>{this.props.subText}</p>
            }
            {this.props.children}
            <Button 
              onClick={this.props.onClose}
              text={this.props.buttonText ? this.props.buttonText : "Continue"}
              icon="check"  
            />
          </div>
        </div>
      </Transition>
    )
  }
}

export default Alert;