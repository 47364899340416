import React, { Component, Fragment } from 'react'
import { ReactComponent as BurgerSVG } from '../../../assets/svgs/hamburger.svg';
import { TimelineMax, Power1 } from 'gsap';
import styles from './BurgerMenu.module.scss';
import { Consumer } from '../../../context';

class BurgerMenu extends Component {
  constructor(props){
    super(props);
    this.state = {}
    this.tl = null
  }

  componentDidMount(){
    this.tl = new TimelineMax({ paused: true, reversed: true });
    this.tl
      .to('#top', .5, { rotation: 45, ease: Power1.easeInOut }, 'menu')
      .to('#bot', .5, { rotation: -45, ease: Power1.easeInOut }, 'menu')
      .to('#mid', .5, { autoAlpha: 0 }, 'menu');
  }
  //
  burgerToggle = () => {
    this.tl.reversed() ? this.tl.restart() : this.tl.reverse();
  }

  //
  render() {
    return (
      <Consumer>
        {(context) => (
          <Fragment>
            <BurgerSVG className={styles.menuIcon} onClick={() => {
              this.burgerToggle();
              context.toggleMenu();
              context.fireFunc(this.burgerToggle)
            }} />
          </Fragment>
        )}
      </Consumer>
    )
  }
}

export default BurgerMenu;
