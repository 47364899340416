import React, { Component, Fragment } from 'react'
import Loader from '../shared/Loader';
import Button from '../shared/Button';
import data from '../../assets/json/core_content.json';
import { ReactComponent as DropReveal } from '../../assets/images/dropdown.svg';
import { Transition } from 'react-transition-group';
import { TweenMax } from 'gsap';

class Life extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showAlert: false,
      data: data
    }
    this.content = null;
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: !this.state.isLoading, showAlert: true })
    }, 2500);
  }

  toggleAccordion = (e) => {
    this.setState(
      {
        [`section-1-show`]: false,
        [`section-2-show`]: false,
        [`section-3-show`]: false,
        [`section-${e.target.dataset.section}-show`]: this.state[`section-${e.target.dataset.section}-show`] ? !this.state[`section-${e.target.dataset.section}-show`] : true
      }
    )
  }

  buildPage = () => {
    const { data } = this.state;
    return (
      <Fragment>
        <div className="inner-content">
          <h2>{data.life.intro}</h2>
          <p>{data.life.summary}</p>
          <ol>
            {data.life.summary_points.map((point, i) => <li key={i}>{point}</li>)}
          </ol>
          <p>{data.life.summary_wrap}</p>
        </div>
        {data.life.dropdowns.map((section, i) => {
          return (
            <div key={i} className={`dropdown-section ${this.state[`section-${i + 1}-show`] === true ? 'panel-show' : null}`}>
              <h3 key={i} data-section={i + 1} onClick={this.toggleAccordion}>{section.header}<span className="dropdown-header"><div className="expand-icon"><DropReveal id="icon" /></div></span></h3>
              <div className="inner-section">
                <h4>{section.content_title}</h4>
                <ul>
                  {section.points.map((point, i) => <li key={i}>{point}</li>)}
                </ul>
              </div>
            </div>
          )
        })}
      </Fragment>
    )
  }
  //
  renderLoader = () => {
    if (this.state.isLoading) {
      return (
        <Loader in={this.state.isLoading} />
      )
    } else {
      return (
        <Transition
          timeout={5000}
          mountOnEnter
          unmountOnExit
          appear
          in={!this.state.isLoading}
          addEndListener={(node, done) => {
            TweenMax.from(node, 1, { autoAlpha: !this.state.isLoading ? 0 : 1, onComplete: done });
          }}
        >
          <div ref={content => this.content = content}>
            {this.buildPage()}
            <div className="button-container">
              <Button
                text="Next"
                icon="check"
                onClick={() => this.props.history.push('/home')}
              />
            </div>
          </div>
        </Transition>
      )
    }
  }
  //
  render() {
    return (
      <div>
        {this.renderLoader()}
      </div>
    )
  }
}
export default Life;