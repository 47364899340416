import React, { Component } from 'react'
import content from '../../assets/json/core_info.json';
import Button from '../shared/Button';
import CircleCheckAnimation from '../shared/CircleCheckAnimation';
import {Context} from '../../context';

class Outro extends Component {
  constructor(props){
    super(props);
    this.state = {
      content
    }
  }
 signOut = () =>{
    this.context.signOut()
    this.props.history.push('/')
  }

  render() {
    const { content } = this.state;
    return (
      <div className="outro-container">
        <div className="header-box">
          <h1>Congratulations!</h1>
          <div className="circle-check-container">
            <CircleCheckAnimation />
          </div>
        </div>
        <p>{content.app_outro_text}</p>
        <div className="button-box">
          <Button 
            text="Home"
            icon="back"
            onClick={() => this.props.history.push('/home')}
          />
          <Button 
              text="Sign Out"
              icon="close"
              onClick={() => this.signOut()}
          />
        </div>
      </div>
    )
  }
}
Outro.contextType = Context;
export default Outro;