import React, { Component, Fragment } from 'react'
import Button from '../../shared/Button';
import PageProgBar from '../../shared/PageProgBar';
import data from '../../../assets/json/core_content.json';

import { Consumer } from '../../../context';
import Triangle from '../../shared/DropdownTriangle/Triangle'

class Mod1Page3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showAlert: false,
      data: data,

    }
    this.content = null;
  }

  toggleAccordion = (e) => {  //Collapses all dropdowns and opens target dropdown


    this.setState(
      {
        [`section-1-show`]: false,
        [`section-2-show`]: false,
        [`section-3-show`]: false,
        [`section-4-show`]: false,
        [`section-5-show`]: false,
        [`section-6-show`]: false,
        [`section-7-show`]: false,
        [`section-${e.target.dataset.section}-show`]: this.state[`section-${e.target.dataset.section}-show`] ? !this.state[`section-${e.target.dataset.section}-show`] : true,

      }, () => {
      }
    )

  }

  buildPage = () => {

    const { data } = this.state;
    return (
      <Fragment>
        <div className="inner-content">
          <p>{data.life.summary}</p>
          <p>{data.life.summary_wrap}</p>
        </div>
        {data.life.dropdowns.map((section, i) => {
          return (
            <div key={i} className={`dropdown-section ${this.state[`section-${i + 1}-show`] === true ? 'panel-show' : null}`}>
              <h3 key={i} data-section={i + 1} onClick={this.toggleAccordion}>{section.header}
                <span className="dropdown-header">
                  <div className="expand-icon"><Triangle data={i + 1} id="icon" /></div>
                </span>
              </h3>
              <div className={this.state[`section-${i + 1}-show`] === true ? "inner-section" : 'hide'}>
                <ul>
                  {section.points.map((point, i) => <li key={i}>{point}</li>)}
                </ul>
              </div>
            </div>
          )
        })}
      </Fragment>
    )
  }

  render() {
    return (
      <Consumer>
        {(context) => (
          <div ref={content => this.content = content}>
            <PageProgBar pageTitle="FAQ's" />
            {this.buildPage()}
            <div className="button-container">
              <Button
                text="Next"
                icon="check"
                onClick={() => {
                  context.goNextPage(context.state.currentModule) === undefined
                    ? this.props.history.push('/mod-1-conclude')
                    : context.goNextPage(context.state.currentModule);
                  if (context.state[`module${context.state.currentModule}status`] !== 2) {
                    if (!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress(context.state.currentModule, 2, 2) // (current mod), (status to be applied), (target for unlock)
                  }
                }}
              />
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}

export default Mod1Page3;