import React, { Component } from 'react'
import styles from './DragTextHolder.module.scss';
import { Draggable, TweenLite, Power3 } from 'gsap/all';
import { ThrowPropsPlugin } from '../../../vendor/gsap/ThrowPropsPlugin';


class DragTextHolder extends Component {
  constructor(props){
    super(props);
    this.state = {}
    this.textHolder = null;
  }

  componentDidMount(){
    console.log(ThrowPropsPlugin);
    if(this.props.actStarted){
      TweenLite.set(this.textHolder, { 
        autoAlpha: 0,
        x: (window.innerWidth / 2) - this.textHolder.getBoundingClientRect().width / 2,
        y: 800 
      })
      TweenLite.to(this.textHolder, .5, { autoAlpha: 1, y: this.props.boxRef.getBoundingClientRect().bottom, delay: .7, onComplete: this.setDragger })  
    }
    this.props.onResize(this.onResize)
  }

  componentWillUnmount(){
    this.props.removeResize(this.onResize);
  }

  //FUNCTION TO RESET TEXTBOX ON SCREEN SIZE -OR- ORIENTATION CHANGE//
  onResize = () => {
    if(!this.props.activityEnded){
      TweenLite.set(this.textHolder, { 
        autoAlpha: 1,
        x: (window.innerWidth / 2) - this.textHolder.getBoundingClientRect().width / 2,
        y: this.props.boxRef.getBoundingClientRect().bottom,
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.text !== prevState.text){
      return { newText: nextProps.text }
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.text !== null){
      if(prevProps.text !== this.props.text){
        this.setState({ newText: this.props.text }, () => {
          if(this.props.activityStarted){
            TweenLite.delayedCall(.1, () => {
              TweenLite.set(this.textHolder, { 
                autoAlpha: 0,
                x: (window.innerWidth / 2) - this.textHolder.getBoundingClientRect().width / 2,
                y: 800
              })
              TweenLite.to(this.textHolder, .5, { autoAlpha: 1, y: this.props.boxRef.getBoundingClientRect().bottom, delay: .7, onComplete: this.setDragger })   
            })
          } else return;
        })
      }
    }
  }

  setDragger = () => {
    const { targetRefs, current, lidRef, setText, boxRef } = this.props;
    const { correctHit, incorrectHit } = styles;
    const textHolder = this.textHolder;
    Draggable.create(this.textHolder, {
      type: 'x, y',
      dragClickables: true,
      throwProps: true,
      bounds: document.querySelector('.page-content'),
      throwResistence: 2000,
      dragResistence: 0.8,
      onDragStart: this.onDrag,
      onDragEnd: function onRelease(){
        let hit = false, correct = false, boxHit, targetNumber;
        targetRefs.forEach((target, i) => { 
          if(this.hitTest(targetRefs[i], '20%')){
            boxHit = targetRefs[i];
            hit = true;
            correct = boxHit.getAttribute('data-type') === current.type;
            targetNumber = i;
          } 
        })
        if(hit && correct){
          TweenLite.set(textHolder, { className: `+=${correctHit}` })
          const rect = boxHit.getBoundingClientRect();
          const textBox = this.target.getBoundingClientRect()
          TweenLite.to(lidRef[targetNumber], .5, { y: -lidRef[0].getBoundingClientRect().height, onComplete: () => {
            TweenLite.to(lidRef[targetNumber], .2, { y: 0, delay: 0.5 })
          }});
          TweenLite.to(this.target, 0.5, { 
            x: rect.left + (rect.width / 2) - textBox.width / 2, 
            y: rect.top + (rect.height / 2) - textBox.height * 3, 
            ease: Power3.easeInOut,
            onComplete: () => {
              TweenLite.to(this.target, 0.5, { autoAlpha: 0, scaleX: 0.1, scaleY: 0.1, ease:Power3.easeIn, onComplete: () => {
                setText();
                TweenLite.set(this.target, { className: `-=${correctHit}` });
                TweenLite.set(this.target, {
                  scaleX: 1,
                  scaleY: 1, 
                  autoAlpha: 0,
                  x: (window.innerWidth / 2) - this.target.getBoundingClientRect().width / 2,
                  y: boxRef.getBoundingClientRect().bottom,
                })
              }});	
            }
          });
        } else if(hit && !correct){
          TweenLite.set(textHolder, { className: `-=${correctHit}` });
          TweenLite.set(textHolder, { className: `+=${incorrectHit}` })
          TweenLite.to(this.target, 0.5, { 
            x: (window.innerWidth / 2) - this.target.getBoundingClientRect().width / 2, 
            y: boxRef.getBoundingClientRect().bottom, 
            ease:Power3.easeIn,
            onComplete: () => {
              TweenLite.to(textHolder, .7, { className: `-=${incorrectHit}` });
            }});	
          } else {
            TweenLite.to(this.target, 0.5, { 
              x: (window.innerWidth / 2) - this.target.getBoundingClientRect().width / 2, 
              y: boxRef.getBoundingClientRect().bottom, 
              ease:Power3.easeIn });	
          }
        }
      }
    );
  }

  render() {
    return (
      <div ref={textHolder => this.textHolder = textHolder} className={styles.container}>
        <h3>{this.props.text ? this.props.text : null}</h3>
      </div>
    )
  }
}

export default DragTextHolder;