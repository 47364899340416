
class BackEndServiceClass {

  async login(username, password) {
    let user = { username: username, password: password };

    console.log(user)
    const response = await fetch('/api/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      body: JSON.stringify(user)
    });
    if (!response.ok) {
      return false
    } else {

      let text = await response.text();
      return text;
    }

  }


  async getUser(token) {
    const response = await fetch(`/api/user/`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` },
    }).catch(err => {
      console.log(err)
    });

    let user = await response.json();
    return user
  }


  async updateUser(token, data) {
    let userData = JSON.stringify(data)
    const response = await fetch(`/api/user/`, {
      method: 'PUT',
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=utf-8' },
      body: userData
    }).catch(err => {
      return err
    })

  }

  setItem = async (key, value, userId, token) => {
    // this will save the users notes from the modules  
    let holder = {}
    holder.name = key
    holder.value = value
    holder.userId = userId
    const JSONobject = JSON.stringify(holder);
    let response;
    response = await fetch(`/api/bookmark`, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=utf-8' },
      body: JSONobject
    })

    if (!response.ok) {
      this.putItem(JSONobject, token)
    }

    return (response)
  }

  putItem = async (JSONobject, token) => {
    let response = await fetch('/api/bookmark', {
      method: 'PUT',
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json;charset=utf-8' },
      body: JSONobject
    })
    return response
  }



  getItem = async (key, userId, token) => {
    // bookmarkedTopics = key
    // touse = key should return a string with the user value
    const response = await fetch(`/api/bookmark/${userId}/${key}`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` },
    }).catch(err => {
      console.log(err)
    })


    if (!response.ok) {
      return false
    }

    return response.json()

  }


}


const BackEndService = new BackEndServiceClass();
Object.freeze(BackEndService);
export default BackEndService;