import React, { Component } from 'react'
import Button from '../../shared/Button';
// import AddToCalendar from 'react-add-to-calendar';
// import * as ICS from 'ics-js';

class Mod4Remind extends Component {
  constructor(props){
    super(props);
    this.state = {
      frequency: 'Daily',
      time: '1:00',
      action: 'Do one of your supportive actions.',
      am_pm: 'AM'
    }
  }
  //
  handleSelectChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  //
  handleSubmitReminder = (e) => {
    e.preventDefault();
    console.log('Form Submitted With DATA:', this.state);
  }

  // createCalendarEvent = () => {

  // }
  //
  render() {
    return (
      <div className="page-content">
        <div className="inner-page-content">
          <div className="header-row">
            <h1>Add a Reminder</h1>
            <div className="button-box">
              <Button 
                text="Back"
                icon="back"
                onClick={() => this.props.history.goBack()}
              />
            </div>
          </div>
          <p>Use this form to set a reminder to be supportive to your family member. A calendar event will be created once you click "Add to Calendar."</p>
        </div>
        <div className="reminder-form-wrap">
          <form onSubmit={this.handleSubmitReminder}>
            <label>Frequency:</label>
            <select name="frequency" value={this.state.frequency} onChange={this.handleSelectChange}>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
            </select>
            <label>Time:</label>
            <div className="time-wrap">
              <select name="time" value={this.state.time} onChange={this.handleSelectChange}>
                <option>1:00</option>
                <option>1:30</option>
                <option>2:00</option>
                <option>2:30</option>
                <option>3:00</option>
                <option>3:30</option>
                <option>4:00</option>
                <option>4:30</option>
                <option>5:00</option>
                <option>5:30</option>
                <option>6:00</option>
                <option>6:30</option>
                <option>7:00</option>
                <option>7:30</option>
                <option>8:00</option>
                <option>8:30</option>
                <option>9:00</option>
                <option>9:30</option>
                <option>10:00</option>
                <option>10:30</option>
                <option>11:00</option>
                <option>11:30</option>
                <option>12:00</option>
                <option>12:30</option>
              </select>
              <select name="am_pm" value={this.state.am_pm} onChange={this.handleSelectChange}>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
            <label>Action:</label>
            <select name="action" value={this.state.action} onChange={this.handleSelectChange}>
              <option value="Do one of your supportive actions.">Do one of your supportive actions.</option>
              <option value="Say one of your supportive phrases.">Say one of your supportive phrases.</option>
              <option value="Use one of your supportive words.">Use one of your supportive words.</option>
              <option value="Plan one of your supportive activities.">Plan one of your supportive activities.</option>
            </select>
            <Button 
              text="Add to Calendar"
              icon="add"
            />
            {/* <AddToCalendar /> */}
          </form>
        </div>
      </div>
    )
  }
}
export default Mod4Remind;