import React from 'react';
import styles from './CheckBox.module.scss';

const CheckBox = (props) => {
  const { 
    checkContainerClass, 
    labelName, 
    labelValue, 
    labelText,
    onChange, 
    checked,
    dataAttr
    } = props;
  return(
    <div className={styles[`${checkContainerClass}`]}>
      <label>
        <input
          data-checkbox={dataAttr}
          type="checkbox"
          className={styles.checkCustom} 
          name={labelName} 
          value={labelValue}
          onChange={onChange} 
          defaultChecked={checked}
        >
        </input>
        <span className={styles.checkmark}></span>
      </label>{labelText}
    </div>
  )
}

export default CheckBox;
