import React, { Component } from 'react'
import { ReactComponent as CloseHelp } from '../../../assets/svgs/close_btn.svg';
import data from '../../../assets/json/help_system.json';


class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      currentHelpContent: ''
    }
  }

  render() {
    return (
      <div className={`help-content ${this.props.helpShow ? 'help-show' : 'help-hide'}`}>
        <div className="help-header">
          <h1>For technical support, please email info@interlockresearch.org.</h1>
          <div className="help-close-btn">
            <CloseHelp onClick={this.props.toggleHelp} />
          </div>
        </div>
        {/* <div className="help-text-container">
          {this.state.data[this.props.currentPage]}
        </div> */}
      </div>
    )
  }
}
export default Help;
