import React, { Component, } from 'react'
import Button from '../../shared/Button';
import { Consumer } from '../../../context';

class Mod2Conclude extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }


  render() {
    return (
      <Consumer>
        {(context) => (
          <div className="inner-page-content conclusion">
            <h1 className="conclude-header">What Did You Learn?</h1>
            <p className="conclude-txt">There are many ways to support a family member in not drinking.  Through this app, you have found ways to be supportive that you are willing to do, and that your family member appreciates.</p>
            <p className="conclude-txt">Remember that doing anything new is hard, change takes time, your family member may slip up and drink, and you may find yourself doing or saying things that aren’t supportive. The most important thing you can do is to keep trying, and keep communicating with your family member.  Things can be better!</p>
            <div className="button-box">
              <Button
                text="Next"
                icon="next"
                onClick={() => {
                  this.props.history.push('/mod-2-results');
                }}
              />
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}

export default Mod2Conclude;