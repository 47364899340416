import React, { Component } from 'react'
import Button from '../../shared/Button';
import { Consumer, Context } from '../../../context';
import PageProgBar from '../../shared/PageProgBar';
import Alert from '../../shared/Alert';
import data from '../../../assets/json/core_content.json';
import BackendService from '../../../utils/BackendService'
class Mod2Page3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: [
        { "name": "cansay", "content": "Things you can say:" },
        { "name": "coulddo", "content": "Things you could do:" },
        { "name": "touse", "content": "Important words to use:" },
        { "name": "toavoid", "content": "Important words to avoid:" }
      ],
      currentIndex: 1,
      section1show: true,
      showNext: false,
      pageUnlocked: false,
      showAlert: false
    }
  }

  historyPusher = (path) => {
    this.props.history.push(path);
  }

  nextInputAndSubmit = (cur) => {
    this.setState({
      [`section${cur}show`]: true,
    }, () => {
      this.setState({ currentIndex: this.state.currentIndex + 1 })
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
    })
  }


  createInputs = () => {
    let page = window.location.pathname;
    const inputs = this.state.inputs.map((input, i) => {
      return (
        <div key={i} className={(this.context.state.unlockedPages.includes(page)) ? "input-section" : this.state[`section${i + 1}show`] ? "input-section" : "input-section"}>
          <label className="bold">{input.content}</label>
          <input
            type="text"
            name={input.name}
            onChange={this.onInputChange}
            value={this.state[input.name] ? this.state[input.name] : ''}
          />
        </div>
      )
    })
    return inputs;
  }

  postToLocalStorage = () => {  //saving the inputs // POST REQUEST
    const { cansay, coulddo, touse, toavoid } = this.state
    // localStorage.setItem('cansay', cansay);
    // localStorage.setItem('coulddo', coulddo);
    // localStorage.setItem('touse', touse);
    // localStorage.setItem('toavoid', toavoid);
    this.setState({ showAlert: !this.state.showAlert })

    this.context.setItem('cansay', cansay)
    this.context.setItem('coulddo', coulddo)
    this.context.setItem('touse', touse)
    this.context.setItem('toavoid', toavoid)

  }
  updateNextButton = () => {
    this.setState({ showNext: !this.state.showNext })
  }

  alertClose = () => {
    this.setState({ showAlert: !this.state.showAlert })
  }

  render() {
    return (
      <Consumer>
        {(context) => (
          <div>
            <PageProgBar pageTitle="How Can You Be Supportive?" />
            <Alert
              showAlert={this.state.showAlert}
              text="Your ideas have been saved."
              subText="You can find them in the Supporting Changes in Drinking: 'Bookmarks' page."
              buttonText="OK"
              onClose={() => this.alertClose()}
            />
            <div className="inner-page-content">
              <div className="col-section">
                <div className="desc-txt">
                  <p>There are many ways to be supportive.  You can be supportive through what you say or what you do.  Often people say or do things for others that they assume are nice.  But people don't always know what the other person wants.  In this exercise, you will come up with ideas for how <span className="emphasis-text">you</span> think you can be supportive.  Then, you will ask your family member for input about what <span className="emphasis-text">he or she</span> would find supportive.</p>
                  <br />
                  <p>{data.m4.summary}</p>
                  <ul>
                    {data.m4.summary_points.map((point, i) => <li key={i}>{point}</li>)}
                  </ul>
                  <br />
                  <p>After this exercise, you'll know how to provide support that your family member finds helpful for not drinking.</p>
                </div>
              </div>
              <div className="button-box">
                <Button
                  text="Next"
                  icon="next"
                  onClick={() => {
                    this.props.history.push(context.goNextPage(context.state.currentModule));
                    if (context.state[`module${context.state.currentModule}status`] !== 2) {
                      if (!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}
Mod2Page3.contextType = Context;
export default Mod2Page3;