import React, { Component } from 'react'
import { ReactComponent as Logo } from '../../assets/images/bsmart_logo.svg';

class NotFound extends Component {
  render() {
    return (
      <div className="not-found-container">
        <div className="logo-underlay">
          <h1>404: Not Found...</h1> 
          <Logo />        
        </div>
      </div>
    )
  }
}
export default NotFound;