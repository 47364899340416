import React, { Component } from 'react';
import data from './assets/json/core_content.json';
import languageData from './assets/json/languages.json';
import BackendService from './utils/BackendService';
import LoginService from './utils/LoginService';
export const Context = React.createContext();

export class Provider extends Component {
  state = {
    menuShow: false,
    modules: data.modules,
    currentModule: 1, // manual override --> Should start with 1
    unlockedMods: [1], // manual override --> Should be Array init with 1
    unlockedPages: [],
    completedMods: [], // manual override --> Should be empty Array
    isLangMenuOpen: null,
    languageData: languageData,
    firstTimeVisitor: true,
    userToken: null,
    usernameFailed: false,
    passwordFailed: false,
    subtitles: true
  }

  componentDidMount() {
    this.initContext()
    let userToken = localStorage.getItem('userToken');
    if (LoginService.getCookie()) {
      this.setState({
        userToken: userToken
      }, () => {
        if (!this.state.id) {
          this.getUser(this.state.userToken);
        }
      })
    }
    else {
      // if (window.location.pathname !== "/") {
      //   window.location = '/';
      // };
    }
  }


  login = async (username, password) => {
    let userToken = await BackendService.login(username, password)
    if (await userToken) {
      this.setState({
        userToken: userToken,
        name: username
      }, () => {
        console.log(this.state.userToken)
      })
      this.getUser(await userToken)
      this.initContext()

      await LoginService.login(userToken)
      return (userToken)
    }
    else {
      this.setState({
        usernameFailed: true
      })
      return false
    }
  }
  signOut = async () => {
    LoginService.clearCookie();
    localStorage.removeItem('userToken');

  }

  getUser = async (userToken) => {
    let user = await BackendService.getUser(userToken);
    let object = (JSON.parse(user.localStorage));
    this.setState({ id: user.loginId })
    // Loops over the data object containing page progress
    for (let key in object) {
      if (key !== "loginId") {
        this.setState({
          [key]: object[key]
        }, () => {
        }

        )
      }

    }
    return user

  }

  setItem = async (key, value) => {
    let userToken = localStorage.getItem('userToken');
    const response = BackendService.setItem(key, value, await this.state.id, userToken)
    return response

  }

  getItem = async (key) => {
    let userToken = localStorage.getItem('userToken');
    const response = await BackendService.getItem(key, this.state.id, userToken)
    return response
  }

  updateUser = async () => {
    // progress object saves whether or not pages are done.
    let ProgressObject = {}
    for (let key in this.state) {
      if (key !== ("id" || "currentModule" || "unlockedMods" || "unlockedPages" || "completedMods")) {

        ProgressObject[key] = this.state[key];
        // console.log(ProgressObject);
      }
    }
    // this makes sure that certain values goin to the db are in the correct format.
    const dataObject = {
      "loginId": this.state.id,
      "currentModule": JSON.stringify(this.state.currentModule),
      "unlockedMods": JSON.stringify(this.state.unlockedMods),
      "unlockedPages": JSON.stringify(this.state.unlockedPages),
      "completedMods": JSON.stringify(this.state.completedMods),
      "firstTimeVisitor": this.state.firstTimeVisitor,
      "localStorage": JSON.stringify(ProgressObject)
    };
    // console.log(dataObject);
    let key = localStorage.getItem('userToken')
    BackendService.updateUser(key, dataObject);
    if (!LoginService.isLoggedIn()) {
      window.location = '/';
    }

  }

  toggleMenu = () => {
    this.setState({ menuShow: !this.state.menuShow }) //toggle open status of main menu
  }

  fireFunc = (func) => {
    this.setState({ toggleFunc: func })  //Placing func on state
  }

  funcGun = (func) => {
    this.setState({ swipeShooter: func }); //Placing func on state
  }

  hydrateStateWithLocalStorage = () => {
    for (let key in this.state) {
      if (localStorage.hasOwnProperty(key)) {
        let value = localStorage.getItem(key);
        try {
          value = JSON.parse(value);
          this.setState({ [key]: value });
        } catch (e) {
          if (key !== 'id') {
            this.setState({ [key]: value });
          }
        }
      }
    }
    // this.updateUser()
  }

  // saveStateToLocalStorage = () => {
  //   for (let key in this.state) {
  //     if (key !== "id") {
  //       localStorage.setItem(key, JSON.stringify(this.state[key]))
  //     }
  //   }
  // }

  initContext = () => {
    data.modules.map((module, i) => {
      if (!this.state[`module${module.id}pages`]) {
        return (this.setState({
          [`module${module.id}status`]: module.completionStatus, //Initialization of App State Values
          [`module${module.id}pages`]: module.pages.length,
          [`module${module.id}total`]: module.pages.length,
          [`module${module.id}pages-completed`]: 0,
          [`module${module.id}percent-completed`]: 0
        }, () => {
          // this.hydrateStateWithLocalStorage();
          // this.updateUser() 
          //Setting state with LocalStorage // API Request could go here
        }))
      } else {
        return null;
      }
    })
  }

  updateProgress = (modNumber, status, targetModule) => { //currentMod //targetStatus //targetModuleToUnlock
    this.setState({
      [`module${modNumber}status`]: status,
      [`module${this.state.currentModule}pages`]: this.state[`module${this.state.currentModule}pages`] - 1,
    }, () => {
      this.updateUser()
    })
    if (this.state[`module${this.state.currentModule}pages`] <= 1 && targetModule) { // 1 instead of 0 is a HACK FOR UNLOCK PROGRESSION BUG //
      let unlockedMods = this.state.unlockedMods.concat(targetModule);
      let completedMods = this.state.completedMods.concat(this.state.currentModule);
      this.setState({
        unlockedMods: unlockedMods,
        completedMods: completedMods
      }, () => { this.updateUser() })
    }
  }

  unlockPage = (page) => {
    this.setState({ unlockedPages: [...this.state.unlockedPages, page] }, this.updateUser());
  }




  goNextPage = (currentModule) => {
    let currentModPaths = [];
    let currentPage = window.location.pathname;
    this.state.modules[currentModule - 1].pages.map((page, i) => {
      currentModPaths.push(page.path);
      return currentModPaths;
    })
    this.setState({
      currentModPaths: currentModPaths,
      currentPage: currentPage,
      unlockedPages: !this.state.unlockedPages.includes(currentPage) ? [...this.state.unlockedPages, currentPage] : this.state.unlockedPages,
      [`module${currentModule}pages-completed`]: this.state[`module${currentModule}pages-completed`] === this.state[`module${currentModule}total`]
        ? this.state[`module${currentModule}total`]
        : this.state.unlockedPages.includes(currentPage)
          ? this.state[`module${currentModule}pages-completed`]
          : this.state[`module${currentModule}pages-completed`] + 1
    }, () => {
      this.updateUser()
    }
    )
    return currentModPaths[(currentModPaths.indexOf(currentPage) + 1)];
  }
  subtitlesToggle = () => {
    this.setState({
      subtitles: !this.state.subtitles
    })
  }

  updateCurrentMod = (current) => {
    this.setState({ currentModule: current })
  }

  updatePercents = (current, percent) => {
    this.setState({ [`module${current}percent-completed`]: percent })  //Module Percent Completed
  }

  updateProgramPercents = (percent) => {
    this.setState({ [`program-percent-completed`]: percent })
  }

  openLanguageMenu = () => {
    this.setState({ isLangMenuOpen: !this.state.isLangMenuOpen })
  }

  closeLanguageMenu = () => {
    this.setState({ isLangMenuOpen: !this.state.isLangMenuOpen })
  }

  preventHomeInstruct = () => {
    this.setState({ firstTimeVisitor: false })
  }

  render() {
    return (
      <Context.Provider value={{
        state: this.state,
        toggleMenu: this.toggleMenu,
        fireFunc: this.fireFunc,
        updateProgress: this.updateProgress,
        updateCurrentMod: this.updateCurrentMod,
        goNextPage: this.goNextPage,
        updatePercents: this.updatePercents,
        updateProgramPercents: this.updateProgramPercents,
        unlockPage: this.unlockPage,
        initContext: this.initContext,
        // saveStateToLocalStorage: this.saveStateToLocalStorage,
        // hydrateStateWithLocalStorage: this.hydrateStateWithLocalStorage,
        closeLanguageMenu: this.closeLanguageMenu,
        openLanguageMenu: this.openLanguageMenu,
        preventHomeInstruct: this.preventHomeInstruct,
        funcGun: this.funcGun,
        login: this.login,
        updateUser: this.updateUser,
        subtitlesToggle: this.subtitlesToggle,
        signOut: this.signOut,
        clearDB: this.clearDB,
        setItem: this.setItem,
        getItem: this.getItem
      }}>
        {this.props.children}
      </Context.Provider>
    )
  }
}
export const Consumer = Context.Consumer;