import React, { Component } from 'react'
import { Consumer } from '../../../context';
import data from '../../../assets/json/m4_convo_vids.json';
import PageProgBar from '../../shared/PageProgBar';
import VideoPlayer from '../../shared/VideoPlayer';
import Button from '../../shared/Button';
import clip1 from '../../../assets/audio/clip_one.mp3';
import clip2 from '../../../assets/audio/clip_two.mp3';
import { VIDEO_DOMAIN } from '../../../config';

class Mod4Page3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      showAlert: false,
      currentVideo: data.conversation.comm_en,
      alertText: data.instruct,
      closeFunc: this.start,
      isVideoOne: true,
      showNext: false,
      currentAudio: clip1
    }
    this.currentLanguage = null;
  }

  componentDidMount() {
    const _Localize = window.Localize;
    this.currentLanguage = _Localize.getLanguage();
    setTimeout(() => {
      this.setState({ showAlert: true })
    }, 750)
  }

  start = () => {
    this.setState({
      showAlert: false,
      activityStarted: true,
      videoOnePlaying: true
    })
  }

  onVideoEnd = () => {
    if (this.state.videoOnePlaying) {
      this.videOneComplete();
    } else {
      this.videoTwoComplete();
    }
  }

  videOneComplete = () => {
    this.setState({
      videoOnePlaying: false,
      showAlert: true,
      alertText: this.state.data.conversation.response,
      currentVideo: this.state.data.conversation.video_two,
      currentAudio: clip2,
      closeFunc: () => {
        this.setState({
          showAlert: false
        }, () => {
          setTimeout(() => {
            this.setState({
              showFinalAlert: true,
              finalText: this.state.data.conversation.summary,
              subBold: true,
              closeFunc: () => this.setState({ showFinalAlert: false, finalVideoPlaying: true })
            })
          }, 750)
        })
      }
    })
  }

  endActivity = () => {
    this.setState({
      showConclusion: true
    })
  }

  onFinalClose = () => {
    this.setState({
      showConclusion: false,
      activtyEnded: true,
      showNext: true
    })
  }

  historyPusher = (path) => {
    this.props.history.push(path);
  }

  render() {
    const { currentVideo, activtyEnded } = this.state;
    return (
      <Consumer>
        {(context) => (
          <div className="vid-act-container">
            <PageProgBar pageTitle="Video" />
            {activtyEnded && this.showNextButton()}
            <div className="instruct-txt"></div>
            <div className="video-player" ref={videoContainer => this.videoContainer = videoContainer}>
              <VideoPlayer
                key={currentVideo}
                source={this.currentLanguage === 'en' ? `${VIDEO_DOMAIN}comm_en.mp4` : `${VIDEO_DOMAIN}comm_es.mp4`}
                subtitle={this.currentLanguage === 'en' ? 'comm_en.txt' : 'comm_es.txt'}
                subtitleStatus={context.state.subtitles}
              />
            </div>
            <Button
              text='Subtitles'
              icon='info'
              onClick={() => {
                context.subtitlesToggle()
              }}
            />
            <div className="button-container">
              <Button
                text="Next"
                icon="next"
                onClick={() => {
                  this.props.history.push(context.goNextPage(context.state.currentModule));
                  if (context.state[`module${context.state.currentModule}status`] !== 2) {
                    if (!context.state.unlockedPages.includes(window.location.pathname)) context.updateProgress();
                  }
                }}
              />
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}

export default Mod4Page3;
