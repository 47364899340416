import React, { Component } from 'react'
import Button from '../../shared/Button';
import { Consumer, Context } from '../../../context';
import { ReactComponent as EditIcon } from '../../../assets/images/pencil.svg';
import data from '../../../assets/json/core_content.json';
import Triangle from '../../shared/DropdownTriangle/Triangle';
import LoginService from '../../../utils/LoginService';

class Mod2Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      commThoughts: [
        { "name": "cansay", "content": "Things you can say:" },
        { "name": "coulddo", "content": "Things you could do:" },
        { "name": "touse", "content": "Important words to use:" },
        { "name": "toavoid", "content": "Important words to avoid:" }
      ],
      [`section-1-show`]: true,
      [`section-2-show`]: true,
      [`section-3-show`]: true,
      [`section-4-show`]: true,
      isLoading: true
    }
  }
  asyncFunction = async () => {
    let bookmarkedTopics = await this.context.getItem('bookmarkedTopics')
    // this.setState({
    //   bookmarkedTopics: bookmarkedTopics
    // }, ()=>{
    //   console.log(this.state.bookmarkedTopics)
    // })
    if (await bookmarkedTopics) {

      let topics = JSON.parse(await bookmarkedTopics.value)
      let holder = {}
      for (let x = 0; x < topics.length; x++) {
        let value = await this.context.getItem(topics[x])
        holder[topics[x]] = value.value
      }
      this.setState({
        topics: topics,
        userResponses: holder,
        isLoading: false
      }, () => {
        console.log(this.state.userResponses)
      })
    }
    //   else{
    //    this.middleWare()
    //  }

  }
  middleWare = () => {
    setTimeout(() => {
      this.asyncFunction()
    }, 1000)
  }

  componentDidMount() {

    this.asyncFunction()
  }

  toggleAccordion = (e) => {
    this.setState({
      [`section-${e.target.dataset.section}-show`]:
        this.state[`section-${e.target.dataset.section}-show`]
          ? !this.state[`section-${e.target.dataset.section}-show`]
          : true
    })
  }

  toggleBookmarkedTopics = (e) => {
    this.setState({
      [`bookmark-section-${e.target.dataset.section}-show`]:
        this.state[`bookmark-section-${e.target.dataset.section}-show`]
          ? !this.state[`bookmark-section-${e.target.dataset.section}-show`]
          : true
    })
  }

  renderDropdown = () => {
    const thoughts = this.state.commThoughts.map((section, i) => {
      return (
        <div
          key={i}
          className={`alt-dropdown-section ${this.state[`section-${i + 1}-show`] === true ? 'panel-show-result' : null}`}
        >
          <h3
            key={i}
            data-section={i + 1}
            onClick={this.toggleAccordion}
          >
            {section.content}
            <span className="dropdown-header">
              <div className="expand-icon">
                <Triangle data={i + 1} id="icon" />
              </div>
            </span>
          </h3>
          <div className="inner-section">
            <div
              className="edit-said-thing"
              onClick={() => this.props.history.push('/mod-2-idea-edit')}
            >
              <h4>{this.state.isLoading ? null : this.state.userResponses[section.name]}
              </h4>
              <span>
                <div className="edit-icon-wrap">
                  <EditIcon />
                </div>
              </span>
            </div>
          </div>
        </div>
      )
    })
    return thoughts;
  }

  renderBookmarkedTopics = () => {
    let holder = this.context.getItem('bookmarkedTopics');
    let bookmarkedTopics = JSON.parse(holder.value);

    // let bookmarkedTopics = ['something', 'somethingels']
    const toShow = this.state.data.m4.dropdowns.map((topic, i) => {
      if (bookmarkedTopics && bookmarkedTopics.includes(topic.key)) {
        return (
          <div
            key={i}
            className={`bookmark-topic-section ${this.state[`bookmark-section-${i + 1}-show`] === true ? 'panel-show' : null}`}
          >
            <h3
              key={i}
              data-section={i + 1}
              onClick={this.toggleBookmarkedTopics}
            >
              {topic.header}
              <span className="dropdown-header">
                <div className="expand-icon">
                  <Triangle data={i + 1} id="icon" />
                </div>
              </span>
            </h3>
            <div className="inner-section">
              <p>{topic.content_intro}</p>
              <ul>
                {topic.points.map((point, i) => <li key={i}>{point}</li>)}
              </ul>
            </div>
          </div>
        )
      } else {
        return null
      }
    })
    return toShow;
  }

  render() {
    return (
      <div>
        <div className="reminder-prompt">
          <h1>Your Bookmarks:<br />Supporting Changes in Drinking</h1>
          <div className="btn-holder">
            <Button
              text="Add Reminders"
              icon="add"
              onClick={() => this.props.history.push('/mod-2-remind')}
            />
          </div>
        </div>
        <h2 className="red-sub-headers">How Can You Be Supportive?<br />Your Plans:</h2>
        {this.renderDropdown()}
        <h2 className="red-sub-headers">Putting It All Together.<br />Bookmarked Topics:</h2>
        {async () => await this.renderBookmarkedTopics()}
        <div className="button-container">
          <Button
            text="Home"
            icon="next"
            onClick={() => this.props.history.push('/home')}
          />
        </div>
      </div>
    )
  }
}
Mod2Results.contextType = Context
export default Mod2Results;