import React, { Component } from 'react'
import Button from '../../shared/Button';

class Mod3Conclude extends Component {
  render() {
    return (
      <div className="inner-page-content conclusion">
        <h1 className="conclude-header">What Did You Learn?</h1>
        <ol className="conclude-points">
          <li>Doing things together with someone who cares can help a person stick with their goal to change their drinking.</li>
          <li>It’s not that hard to find 1–2 hours in the week to do something positive and fun.</li>
          <li>We all get stuck in routine ways of living and feel like there is nothing new or exciting to look forward to.</li>
          <li>Planning a new shared activity or reviving an old one can be satisfying and fun, and support changes in drinking.</li>
        </ol>
        <div className="button-box">
          <Button
            text="Next" 
            icon="next"
            onClick={() => {
              this.props.history.push('/mod-3-results');
            }}
          />
        </div>
      </div>
    )
  }
}
export default Mod3Conclude;