import React, { Component } from 'react'
import styles from './ProgressModHolder.module.scss';
import { TweenMax } from 'gsap';
import { DrawSVGPlugin } from '../../../vendor/gsap/DrawSVGPlugin';
import data from '../../../assets/json/core_content.json';
import { Context } from '../../../context';
import TweenLite from 'gsap/TweenLite';
//

class ProgHolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data
    }
    this.progBarRect = null;
    this.progBarStatus = null;
    this.container = null;
    this.recPath = [];
  }
  //
  componentDidMount() {
    console.log(DrawSVGPlugin);
    TweenMax.from(this.container, .4, { y: -300, delay: 1 });
    TweenLite.delayedCall(0, () => {
      let modulesCompleted = this.context.state.completedMods.length;
      let totalModules = this.state.data.modules.length - 1; //minus 1 because there are 1 less lines than squares/circles
      let percentCompleted = ((modulesCompleted / totalModules) * 100).toFixed(2);
      this.progressAnimSetter(modulesCompleted, percentCompleted);
      if (this.context.state.unlockedMods.includes(this.context.state.currentModule) && this.context.state[`module${this.context.state.currentModule}status`] === 2) {
        return
      } else {
        this.animateProgress(modulesCompleted, percentCompleted);
      }
    })
  }
  //
  animateProgress = (modulesCompleted, percentCompleted) => {
    TweenMax.delayedCall(2, () => {
      if (this.context.state[`module${this.context.state.currentModule}status`] !== 2) {
        if (this.recPath[modulesCompleted]) {
          TweenMax.to(this.recPath[modulesCompleted], 2, { drawSVG: "100%", delay: .5 })
          TweenMax.to(this.progBarStatus, 1, { drawSVG: `${percentCompleted}%`, onComplete: this.context.updateProgramPercents(percentCompleted) });
        }
      } else {
        return;
      }
    });
  }
  //
  progressAnimSetter = (modulesCompleted, percentCompleted) => {
    if (this.context.state[`module${this.context.state.currentModule}status`] === 2) {
      TweenMax.set(this.progBarStatus, { drawSVG: `${percentCompleted}%` });
    } else {
      TweenMax.set(this.progBarStatus, { drawSVG: `${percentCompleted}%` });
    }
    this.recPath.map((item, i) => {
      if (i < modulesCompleted) {
        return TweenMax.set(item, { drawSVG: "100%", fill: 'green' })
      } else {
        return TweenMax.set(item, { drawSVG: "50% 50%" })
      }
    })
  }
  //
  createProgressBar = () => {
    const modules = this.state.data.modules.map((module, i) => {
      return (
        <svg id={styles.rec} key={i} viewBox="0 0 87 87" preserveAspectRatio="xMidYMid meet">
          <path className={styles.cls2} d="M400.5,324.5V345A19.5,19.5,0,0,1,381,364.5H340A19.5,19.5,0,0,1,320.5,345V304A19.5,19.5,0,0,1,340,284.5h41A19.5,19.5,0,0,1,400.5,304v20.5" transform="translate(-317 -281)" />
          <path className={styles.cls} ref={recPath => this.recPath[i] = recPath} d="M400.5,324.5V345A19.5,19.5,0,0,1,381,364.5H340A19.5,19.5,0,0,1,320.5,345V304A19.5,19.5,0,0,1,340,284.5h41A19.5,19.5,0,0,1,400.5,304v20.5" transform="translate(-317 -281)" />
        </svg>
      )
    })
    return modules;
  }
  //
  render() {
    const { container, progressBar, bar, barLine, barLineColor, nums } = styles;
    return (
      <div className={container} ref={container => this.container = container}>
        <div className={progressBar}>
          {this.createProgressBar()}
          <div className={nums}>
            <p>1</p>
            <p>2</p>
            <p>3</p>
            <p>4</p>
          </div>
          <svg id={bar} viewBox="0 0 855 20">
            <line className={barLine} ref={progBarPath => this.progBarPath = progBarPath} y1="10" x2="855" y2="10" />
            <line className={barLineColor} ref={progBarStatus => this.progBarStatus = progBarStatus} y1="10" x2="855" y2="10" />
          </svg>
        </div>
      </div>
    )
  }
}

ProgHolder.contextType = Context;
export default ProgHolder;
